import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebaseConfig"; // Import Firebase auth
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { useRive, useStateMachineInput } from "rive-react";
import imageRiv from "../animations/login.riv";
// Define the Rive state machine and animation file paths
const RIVE_ANIMATION = imageRiv; // Update with the actual path of your Rive file
const STATE_MACHINE_NAME = "State Machine 1";

const Login = ({ checkLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Initialize the Rive animation
  const { rive, RiveComponent } = useRive({
    src: RIVE_ANIMATION,
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  });

  // State machine inputs
  const stateSuccess = useStateMachineInput(
    rive,
    STATE_MACHINE_NAME,
    "success"
  );
  const stateFail = useStateMachineInput(rive, STATE_MACHINE_NAME, "fail");
  const stateHandUp = useStateMachineInput(
    rive,
    STATE_MACHINE_NAME,
    "hands_up"
  );
  const stateCheck = useStateMachineInput(rive, STATE_MACHINE_NAME, "Check");
  const stateLook = useStateMachineInput(rive, STATE_MACHINE_NAME, "Look");

  // Set the "Look" animation value based on the length of the email
  useEffect(() => {
    if (stateLook && email) {
      const lookValue = (email.length / 41) * 100 - 25;
      stateLook.value = Math.max(Math.min(lookValue, 100), 0);
    }
  }, [email, stateLook]);

  // Function to trigger success animation
  const triggerSuccess = () => {
    stateSuccess && stateSuccess.fire();
  };

  // Function to trigger failure animation
  const triggerFail = () => {
    stateFail && stateFail.fire();
  };

  // Set hand-up/down for the bear based on field focus
  const setHangUp = (hangUp) => {
    stateHandUp && (stateHandUp.value = hangUp);
  };

  // Set check animation
  const setCheck = (check) => {
    stateCheck && (stateCheck.value = check);
  };

  // Handle form submission
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setCheck(true); // Trigger check animation

    try {
      await signInWithEmailAndPassword(auth, email, password);
      triggerSuccess(); // Trigger success animation
      navigate("/profile"); // Redirect to profile after successful login
    } catch (err) {
      setError("שגיאת התחברות");
      triggerFail(); // Trigger failure animation
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 100px)",
      }}
    >
      {/* Render the Rive animation */}
      <Box
        sx={{
          width: {
            xs: 200, // 200px for mobile (extra-small screen)
            md: 300, // 300px for desktop (medium screens and above)
          },
          height: {
            xs: 200, // 200px for mobile
            md: 300, // 300px for desktop
          },
          marginBottom: 3,
          overflow: "hidden", // Ensures the content inside is clipped to the circle

          borderRadius: "50%", // This ensures the box is fully circular
        }}
      >
        <RiveComponent />
      </Box>

      {/* Login Form */}
      <Box
        component="form"
        onSubmit={handleLogin}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          maxWidth: 400,
          width: "100%",
        }}
      >
        <Typography variant="h4" textAlign="center" gutterBottom>
          התחברות
        </Typography>

        <TextField
          label="אימייל"
          type="email"
          fullWidth
          required
          value={email}
          onFocus={() => setHangUp(false)} // Hands down when email field is focused
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="סיסמא"
          type="password"
          fullWidth
          required
          value={password}
          onFocus={() => setHangUp(true)} // Hands up when password field is focused
          onChange={(e) => setPassword(e.target.value)}
        />

        {error && (
          <Typography color="error" textAlign="center">
            {error}
          </Typography>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          sx={{ backgroundColor: "#b5651d" }}
        >
          {loading ? <CircularProgress size={24} /> : "שלח"}
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
