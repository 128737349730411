import React from "react";
import { Container, Typography, Box, Divider } from "@mui/material";

const About = () => {
  return (
    <Container sx={{ padding: 4 }}>
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h5" gutterBottom>
          מה זה DevLink?
        </Typography>
        <Typography variant="body1">
          DevLink היא פלטפורמה ייחודית לחיבור בין מפתחים בישראל, המאפשרת לכל
          משתמש ליצור פרופיל אישי, להעלות קורות חיים ולהתחבר עם מפתחים אחרים.
          המטרה היא לייעל את תהליך הגיוס ולסייע לפרילנסרים ושכירים למצוא
          הזדמנויות עבודה במהירות, תוך יצירת שיתופי פעולה משמעותיים. מגייסים
          מרוויחים תהליך גיוס ממוקד ויעיל, שמפחית את זמן החיפוש ואיתור המועמדים
          המתאימים ביותר. מפתחים שנרשמים נהנים מחשיפה להזדמנויות עבודה מגוונות,
          ויצירת קשרים ושיתופי פעולה עם חברות מובילות בשוק.
        </Typography>
      </Box>

      <Divider sx={{ marginY: 2 }} />

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h5" gutterBottom>
          תכונות מרכזיות
        </Typography>
        <Box component="ul">
          <li>יצירת פרופיל אישי - העלאת קורות חיים, תמונה, ומידע מקצועי.</li>
          <li>
            גריד מפתחים - הליבה של מפתחי ישראל המציגה את כל המפתחים עם סוגי
            פילטרים שונים.
          </li>
          <li>
            מפת מפתחים - מפה אינטראקטיבית המציגה מפתחים לפי מיקום גאוגרפי
            בישראל.
          </li>
          <li>
            פיד - גלו את הפיד הסוער של כל המתכנתים, מלא בהשראה, טיפים ועדכונים.
          </li>
          <li>
            צ'אט - שולחים הודעות ישירות ובלחיצת כפתור אל תיבת הדואר של המתכנתים
            המובילים בתחום.
          </li>
          <li>
            לוח משרות - הזדמנות מיוחדת במינה להגיש את מועמדותכם אל מגוון משרות
            בתחום הפיתוח.
          </li>
        </Box>
      </Box>

      <Divider sx={{ marginY: 2 }} />

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h5" gutterBottom>
          יתרונות הפלטפורמה
        </Typography>
        <Box component="ul">
          <li>
            הזדמנויות מקצועיות מגוונות עבור מפתחים מתחילים ומתקדמים. (עם ובלי
            נסיון)
          </li>
          <li>חשיפה ענקית למגוון רחב של מתכנתים, מגייסים, סטארטפים וחברות.</li>
          <li>חיבור לקהילה רחבה של אנשי טק בישראל.</li>
        </Box>
      </Box>

      <Divider sx={{ marginY: 2 }} />

      <Box>
        <Typography variant="h5" gutterBottom>
          Mision & Vision
        </Typography>
        <Typography variant="body1">
          לא עוצרים כאן! הפלטפורמה ממשיכה להתפתח, ואנו מתכננים להוסיף פיצ'רים
          נוספים בהמשך. יש לכם רעיונות? רשמו לנו.
        </Typography>
        <Typography variant="body1">
          כמו כן, אנחנו צריכים את עזרתכם. כל שיתוף, בכל מקום - יעזור לפלטפורמה
          לגדול, להתפתח ולצמוח גבוהה. החזון שלנו הוא ש devlink יהפוך לבית של
          מפתחי ישראל, עם אלפי מפתחים, מאות משרות ושיתופי פעולה.
        </Typography>
        <Box component="ul">
          <li>
            שלב ראשון : הגדלת הקהילה – התמקדות בהרחבת הרשת על ידי הזמנת מפתחים
            לשתף פעולה, להצטרף וליצור קשרים חדשים בפלטפורמה.
          </li>
          <li>
            שלב שני : הצעת משרות בלעדיות – התחלת עבודה עם חברות
            מובילות\סטארטפים\HR כדי להביא הזדמנויות עבודה בלעדיות ומשמעותיות
            למפתחים שלנו.
          </li>
          <li>
            שלב שלישי - נותנים חזרה לקהילה! מיטאפים, אירועי קהילה וכמובן שיתופי
            פעולה עם קהילות מפתחים שונות בישראל.
          </li>
          <li>
            שלב רביעי – הוויז'ן הגדול: המטרה היא להפוך את מציאת המפתחים לפשוטה
            ומהירה הרבה יותר. חברות, מגייסים וסטארטאפים יוכלו להפסיק לבזבז שעות
            ארוכות ומאמץ על חיפוש מועמדים לא יעיל וחסר מיקוד. באמצעות הפלטפורמה
            שלנו, תהליך הגיוס יהפוך לחד ומהיר יותר, ויהיה קל לזהות את המפתחים
            המתאימים ביותר לכל פרויקט. פרילנסרים ושכירים, בין אם הם מנוסים או
            בתחילת דרכם, ימצאו הזדמנויות עבודה במהירות, ובמקביל תיווצר קרקע
            פורייה לשיתופי פעולה משמעותיים בין מפתחים וארגונים.
          </li>
        </Box>
      </Box>
    </Container>
  );
};

export default About;
