import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const EmailVerification = () => {
  const navigate = useNavigate();

  return (
    <Container sx={{ textAlign: "center", padding: 5 }}>
      <Typography variant="h4" gutterBottom>
        אנא אשרו את האימייל
      </Typography>

      <Typography variant="body1" sx={{ marginBottom: 3 }}>
        תודה רבה על ההרשמה, לפני שתוכלו להופיע בסיפרייה - עליכם לאשר את האימייל.
      </Typography>

      <Box>
        <Button
          variant="outlined"
          onClick={() => navigate("/")}
          sx={{ backgroundColor: "#b5651d" }}
        >
          חזרה לסיפרייה
        </Button>
      </Box>
    </Container>
  );
};

export default EmailVerification;
