import React, { useState, useEffect } from "react";
import {
  Container,
  Avatar,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  TextField,
  CircularProgress,
  ListSubheader,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  Chip,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  query,
  where,
  getDoc,
  setDoc,
  serverTimestamp,
  updateDoc,
  increment,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import data from "../data.json"; // Adjust the path based on your project structure
import { auth } from "../firebaseConfig"; // Ensure firebaseConfig exports auth
import VisibilityIcon from "@mui/icons-material/Visibility";

const { cities, professions, experienceLevels, skillSets, sorting } = data;

const Home = () => {
  const theme = useTheme();
  const [developers, setDevelopers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [location, setLocation] = useState("");
  const [profession, setProfession] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [starredDevs, setStarredDevs] = useState([]);
  const [starLoading, setStarLoading] = useState(null); // To keep track of which developer's star is being updated
  const [sortKey, setSortKey] = useState("צפיות"); // Default sorting by Views

  useEffect(() => {
    const savedDevelopers =
      JSON.parse(localStorage.getItem("starredDevelopers")) || [];
    setStarredDevs(savedDevelopers);
  }, []);

  useEffect(() => {
    const fetchDevelopers = async () => {
      setLoading(true);

      // Fetch developers from Firestore
      const querySnapshot = await getDocs(collection(db, "developers"));

      // Map and filter to include only developers with `visible: true`
      const devs = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((dev) => dev.visible === true);

      setDevelopers(devs);
      setLoading(false);
    };

    fetchDevelopers();
  }, []);

  const getSkillsByProfession = () => {
    return profession && skillSets[profession] ? skillSets[profession] : [];
  };

  const toggleStarDeveloper = async (email) => {
    if (starLoading === email) return; // If already loading for this developer, ignore subsequent clicks
    setStarLoading(email); // Set loading state for this developer

    let updatedStarredDevs;

    const developersCollectionRef = collection(db, "developers");
    const q = query(developersCollectionRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("No matching developer found!");
      setStarLoading(null); // Reset loading state
      return;
    }

    const devDocRef = querySnapshot.docs[0].ref;

    // Optimistically update the local state
    setDevelopers((prevDevs) =>
      prevDevs.map((dev) =>
        dev.email === email
          ? {
              ...dev,
              starredCount:
                dev.starredCount + (starredDevs.includes(email) ? -1 : 1),
            }
          : dev
      )
    );

    if (starredDevs.includes(email)) {
      updatedStarredDevs = starredDevs.filter(
        (starredEmail) => starredEmail !== email
      );
      await updateDoc(devDocRef, {
        starredCount: increment(-1),
      });
    } else {
      updatedStarredDevs = [...starredDevs, email];
      await updateDoc(devDocRef, {
        starredCount: increment(1),
      });
    }

    setStarredDevs(updatedStarredDevs);
    localStorage.setItem(
      "starredDevelopers",
      JSON.stringify(updatedStarredDevs)
    );
    setStarLoading(null); // Reset loading state after update is complete
  };

  const handleProfileView = async (devId, userId) => {
    if (devId && userId) {
      // Access the "profileViews" subcollection of the developer document
      const profileViewRef = doc(
        collection(db, "developers", devId, "profileViews"),
        userId
      );
      const docSnap = await getDoc(profileViewRef);

      if (!docSnap.exists()) {
        // If the document doesn't exist, set the view timestamp and increment view count
        await setDoc(profileViewRef, {
          viewedAt: serverTimestamp(), // Use serverTimestamp from Firestore
        });

        // Increment the "views" field in the developer document
        const devRef = doc(db, "developers", devId);
        await updateDoc(devRef, {
          views: increment(1), // Use increment from Firestore
        });
      }
    }
  };

  // Handle multiple skills selection
  const handleSkillsChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSkills(typeof value === "string" ? value.split(",") : value);
  };
  const filteredDevelopers = developers.filter((dev) => {
    const skillMatch = selectedSkills.length
      ? selectedSkills.some((skill) => dev.skills.includes(skill))
      : true;

    // Determine experience level based on years
    const experienceMatch =
      experienceLevel === "Unexperienced"
        ? !dev.hasExperience
        : experienceLevel === "Junior"
        ? dev.hasExperience &&
          dev.experienceYears > 0 &&
          dev.experienceYears <= 5
        : experienceLevel === "Senior"
        ? dev.hasExperience && dev.experienceYears > 5
        : true; // This will allow filtering based on other fields if no experience level is selected

    return (
      (location === "" || dev.location === location) &&
      (profession === "" || dev.profession === profession) &&
      experienceMatch && // Check experience here
      dev.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      skillMatch
    );
  });
  const sortedDevelopers = [...filteredDevelopers].sort((a, b) => {
    if (sortKey === "צפיות") {
      return (b.views || 0) - (a.views || 0); // Sort by views in descending order
    } else if (sortKey === "כוכבים") {
      return (b.starredCount || 0) - (a.starredCount || 0); // Sort by stars in descending order
    }
    return 0;
  });

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", marginTop: 5 }}>
        <CircularProgress sx={{ color: theme.palette.primary.main }} />
        <Typography variant="h6" sx={{ marginTop: 2, color: "text.primary" }}>
          טוען...
        </Typography>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        bgcolor: "background.default",
        minHeight: "100vh",
        padding: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          my: 3,
          fontFamily: "CustomFont, serif",
          textAlign: "center",
          color: "#b5651d",
        }}
      >
        מפתחים
      </Typography>

      <Box sx={{ width: "100%", maxWidth: "900px", marginBottom: 3 }}>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={6} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>מקצוע</InputLabel>
              <Select
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
                label="מקצוע"
                sx={{ borderRadius: "10px", bgcolor: "background.paper" }}
              >
                <MenuItem value="">All Professions</MenuItem>
                {professions.map((prof) => (
                  <MenuItem key={prof} value={prof}>
                    {prof}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>נסיון</InputLabel>
              <Select
                value={experienceLevel}
                onChange={(e) => setExperienceLevel(e.target.value)}
                label="נסיון"
                sx={{ borderRadius: "10px", bgcolor: "background.paper" }}
              >
                <MenuItem value="">All Levels</MenuItem>
                {experienceLevels.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>שפות</InputLabel>
              <Select
                multiple
                value={selectedSkills}
                onChange={handleSkillsChange}
                input={<OutlinedInput label="Skills" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((skill) => (
                      <Chip key={skill} label={skill} />
                    ))}
                  </Box>
                )}
                sx={{ borderRadius: "10px", bgcolor: "background.paper" }}
              >
                {Object.keys(skillSets).map((profession) => [
                  <ListSubheader key={`${profession}-header`}>
                    {profession} Skills
                  </ListSubheader>,
                  ...skillSets[profession].map((skill) => (
                    <MenuItem key={skill} value={skill}>
                      <Checkbox checked={selectedSkills.indexOf(skill) > -1} />
                      <ListItemText primary={skill} />
                    </MenuItem>
                  )),
                ])}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>מיקום</InputLabel>
              <Select
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                label="מיקום"
                sx={{ borderRadius: "10px", bgcolor: "background.paper" }}
              >
                <MenuItem value="">All Locations</MenuItem>
                {cities.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>מיון</InputLabel>
              <Select
                value={sortKey}
                onChange={(e) => setSortKey(e.target.value)}
                label="מיון"
                sx={{ borderRadius: "10px", bgcolor: "background.paper" }}
              >
                {sorting.map((sortOption) => (
                  <MenuItem key={sortOption} value={sortOption}>
                    {sortOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <TextField
              label="חיפוש לפי שם"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ borderRadius: "10px", bgcolor: "background.paper" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3} sx={{ maxWidth: "1200px", padding: 2 }}>
        {sortedDevelopers.map((dev) => (
          <Grid item xs={12} sm={6} md={4} key={dev.id}>
            <Card
              sx={{
                transition: "0.3s",
                "&:hover": {
                  transform: "translateY(-10px)",
                  boxShadow: "0 20px 40px rgba(0,0,0,0.2)",
                },
                borderRadius: "10px",
                boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
                overflow: "hidden",
                bgcolor: "#fdf3e3",
                position: "relative",
                borderLeft: "22px solid #b5651d",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  padding: "0",
                  zIndex: "999",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ padding: 0 }}>
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleStarDeveloper(dev.email);
                    }}
                    color="primary"
                    disabled={starLoading === dev.email} // Disable button while loading
                  >
                    {starredDevs.includes(dev.email) ? (
                      <StarIcon
                        sx={{ padding: 0, color: "#FFD700", fontSize: "26px" }}
                      />
                    ) : (
                      <StarBorderIcon
                        sx={{ padding: 0, color: "#FFD700", fontSize: "26px" }}
                      />
                    )}
                  </IconButton>
                </Box>
              </Box>

              <Link
                to={`/profile/${dev.id}`}
                style={{ textDecoration: "none", flexGrow: 1 }}
                onClick={() => handleProfileView(dev.id, auth.currentUser?.uid)} // Call handleProfileView on click
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "0",
                    left: "-15px",
                    backgroundColor: "#b5651d",
                    color: "#fff",
                    padding: "5px 15px",
                    borderRadius: "0 0 10px 0",
                    fontSize: "12px",
                    fontFamily: "CustomFont",
                  }}
                >
                  {dev.location}
                </Box>

                <Box
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                    height: "100%",
                    justifyContent: "start",
                  }}
                >
                  {dev.profilePicture ? (
                    <CardMedia
                      component="img"
                      image={dev.profilePicture}
                      alt={dev.name}
                      sx={{
                        height: 200,
                        width: 200,
                        objectFit: "cover",
                        borderRadius: "50%",
                        margin: "0 auto",
                        marginBottom: "10px",
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        height: 200,
                        width: 200,
                        margin: "0 auto",
                        marginBottom: "10px",
                      }}
                    >
                      {dev.name.toUpperCase()}
                    </Avatar>
                  )}

                  <CardContent sx={{ textAlign: "center", color: "#4d4d4d" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "'CustomFont', serif",
                        fontWeight: "bold",
                        color: "#4d4d4d",
                        marginBottom: "5px",
                      }}
                    >
                      {dev.name}
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontFamily: "'CustomFont', serif",
                        color: "#5b5b5b",
                        fontWeight: "500",
                      }}
                    >
                      {dev.profession}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        color: "#8c8c8c",
                        fontFamily: "CustomFont",
                        fontStyle: "italic",
                      }}
                    >
                      {dev.hasExperience
                        ? dev.experienceYears > 5
                          ? "Senior"
                          : "Junior"
                        : "Unexperienced"}{" "}
                      {dev.hasExperience && `| ${dev.experienceYears} years`}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        gap: 1,
                        marginTop: 2,
                      }}
                    >
                      {dev.skills && dev.skills.length > 0 ? (
                        dev.skills.map((skill, index) => (
                          <Chip
                            key={index}
                            label={skill}
                            variant="outlined"
                            sx={{
                              borderColor: "#b5651d",
                              color: "#4d4d4d",
                              fontSize: "12px",
                            }}
                          />
                        ))
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{ color: "#8c8c8c", fontStyle: "italic" }}
                        ></Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        position: "absolute",
                        bottom: 10,
                        left: "50%", // Center the box horizontally
                        transform: "translateX(-50%)", // Adjust its position by 50% of its width
                        gap: 2,
                        width: "auto", // Adjust width based on content instead of 100%
                        justifyContent: "center", // Ensure content inside the box is centered
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <StarIcon sx={{ color: "#FFD700", marginRight: 1 }} />
                        {dev.starredCount || 0}
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <VisibilityIcon
                          sx={{ color: "text.primary", marginRight: 1 }}
                        />
                        {dev.views || 0}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>

      {filteredDevelopers.length === 0 && (
        <Typography variant="h6" sx={{ marginTop: 5, color: "text.primary" }}>
          אופס... לא מצאנו מפתחים.
        </Typography>
      )}
    </Container>
  );
};

export default Home;
