import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Avatar } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  where,
  getCountFromServer,
} from "firebase/firestore";
import ReactTimeAgo from "react-timeago";
import { db, auth } from "../../firebaseConfig";
import CommentSection from "./CommentSection";
import ComposePost from "./ComposePost";
import { Link } from "react-router-dom"; // Add Link for navigation
import { likePost } from "./FeedService"; // Ensure the correct path to FeedService

const Feed = () => {
  const [posts, setPosts] = useState([]);
  const [user, setUser] = useState(null);
  const [likeLoading, setLikeLoading] = useState({}); // To track loading state for each post

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    const fetchPosts = async () => {
      try {
        const postsQuery = query(
          collection(db, "posts"),
          orderBy("timestamp", "desc")
        );
        const querySnapshot = await getDocs(postsQuery);

        const postList = await Promise.all(
          querySnapshot.docs.map(async (docSnapshot) => {
            const postData = docSnapshot.data();

            // Fetch developer info using the userID from the post
            const developerInfo = await fetchDeveloperInfo(postData.userId);
            // Fetch comments count
            const commentsCount = await getCommentCount(docSnapshot.id);

            return {
              id: docSnapshot.id,
              ...postData,
              developerInfo, // Attach developer info
              commentsCount, // Attach comments count
            };
          })
        );

        setPosts(postList);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();

    return () => unsubscribe();
  }, []);

  // Function to fetch developer information based on userID from the post
  const fetchDeveloperInfo = async (userID) => {
    try {
      const q = query(
        collection(db, "developers"),
        where("userID", "==", userID)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docSnap = querySnapshot.docs[0];
        return { ...docSnap.data(), docId: docSnap.id }; // Include the document ID
      } else {
        console.log("No such developer found with userID:", userID);
        return {};
      }
    } catch (error) {
      console.error("Error fetching developer info:", error);
      return {};
    }
  };

  // Function to increment comment count in UI after adding a comment
  const incrementCommentCountInUI = (postId) => {
    setPosts((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? { ...post, commentsCount: post.commentsCount + 1 }
          : post
      )
    );
  };

  const getCommentCount = async (postId) => {
    try {
      const commentsCollection = collection(db, `posts/${postId}/comments`);
      const snapshot = await getCountFromServer(commentsCollection);
      return snapshot.data().count || 0;
    } catch (error) {
      console.error("Error fetching comment count:", error);
      return 0;
    }
  };

  const handleLike = async (postId) => {
    if (!user) return; // Check if user is logged in

    const post = posts.find((p) => p.id === postId);
    const hasLiked = post.likedBy.includes(user.uid);

    // If already loading, do nothing to prevent multiple rapid clicks
    if (likeLoading[postId]) return;

    setLikeLoading((prev) => ({ ...prev, [postId]: true })); // Set loading for this post

    try {
      // Optimistically update the UI, ensuring likes don't go below 0
      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId
            ? {
                ...p,
                likes: hasLiked
                  ? Math.max(p.likes - 1, 0) // Ensure likes don't go below 0
                  : p.likes + 1,
                likedBy: hasLiked
                  ? p.likedBy.filter((id) => id !== user.uid)
                  : [...p.likedBy, user.uid],
              }
            : p
        )
      );

      // Call the like/unlike service to update Firebase
      await likePost(postId, hasLiked, user.uid);
    } catch (error) {
      console.error("Error updating likes:", error);

      // Revert UI if there's an error
      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId
            ? {
                ...p,
                likes: hasLiked ? p.likes + 1 : p.likes - 1,
                likedBy: hasLiked
                  ? [...p.likedBy, user.uid]
                  : p.likedBy.filter((id) => id !== user.uid),
              }
            : p
        )
      );
    } finally {
      setLikeLoading((prev) => ({ ...prev, [postId]: false })); // Reset loading state
    }
  };

  const handleDelete = async (postId) => {
    try {
      await deleteDoc(doc(db, "posts", postId));
      setPosts((prevPosts) => prevPosts.filter((p) => p.id !== postId)); // Remove post from state
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: "auto",
        padding: 2,
        minHeight: "100vh",
      }}
    >
      {/* Compose Post Section */}
      {user ? (
        <ComposePost setPosts={setPosts} user={user} />
      ) : (
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 3,
            color: "var(--text-color-secondary)",
          }}
        >
          כדי לרשום משהו, אנא{" "}
          <Link to="/login">
            <Typography
              variant="h6"
              sx={{ paddingLeft: 1, textDecoration: "underline" }}
            >
              התחבר
            </Typography>
          </Link>
        </Typography>
      )}

      {/* Post Feed */}
      {posts.map((post) => (
        <Box
          key={post.id}
          sx={{
            borderBottom: "1px solid var(--hover-color)",
            mb: 3,
            pb: 3,
            backgroundColor: "#fafafa",
            borderRadius: 2,
            boxShadow: "0 1px 3px var(--book-cover-shadow)",
            padding: "16px",
          }}
        >
          {/* Post Header */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
            <Link
              to={`/profile/${post.developerInfo?.docId}`}
              style={{ textDecoration: "none" }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  alt="User Avatar"
                  src={
                    post.developerInfo?.profilePicture || "/defaultAvatar.jpg"
                  } // Safe access to profilePicture with fallback
                  sx={{ width: 36, height: 36 }}
                />
                <Box sx={{ ml: 1.5 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: "bold",
                      color: "var(--text-color-primary)",
                      lineHeight: 1,
                    }}
                  >
                    {post.developerInfo?.name || "User"}{" "}
                    {/* Safely access name */}
                  </Typography>
                  <Box sx={{ direction: "rtl" }}>
                    <Typography
                      variant="caption"
                      sx={{ color: "var(--text-color-secondary)" }}
                    >
                      {post?.timestamp ? (
                        <ReactTimeAgo
                          date={
                            post?.timestamp instanceof Date
                              ? post?.timestamp // If it's already a Date object, use it directly
                              : new Date(post?.timestamp.seconds * 1000) // Convert Firestore Timestamp to JS Date
                          }
                        />
                      ) : (
                        "Unknown time"
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Link>

            {/* Delete button, only visible if the post belongs to the current user */}
            {user && user.uid === post.userId && (
              <IconButton
                onClick={() => handleDelete(post.id)}
                sx={{
                  color: "var(--text-color-secondary)",
                  marginLeft: "auto",
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>

          {/* Post Content */}
          <Typography
            variant="body1"
            sx={{ mb: 2, color: "var(--text-color-primary)" }}
          >
            {post.content}
          </Typography>

          {/* Action Bar */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              maxWidth: 110,
            }}
          >
            <IconButton
              onClick={() => handleLike(post.id)}
              disabled={!user} // Disable liking if the user is not logged in
              sx={{
                color: post.likedBy.includes(user?.uid) // Check if user is null
                  ? "var(--primary-color)"
                  : "var(--text-color-secondary)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FavoriteIcon />
              <Typography
                variant="caption"
                sx={{ ml: 0.5, color: "var(--text-color-primary)" }}
              >
                {post.likes}
              </Typography>
            </IconButton>

            <IconButton
              sx={{
                color: "var(--text-color-secondary)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ChatBubbleOutlineIcon />
              <Typography
                variant="caption"
                sx={{ ml: 0.5, color: "var(--text-color-primary)" }}
              >
                {post.commentsCount || 0}
              </Typography>
            </IconButton>
          </Box>

          {/* Comment Section */}
          <CommentSection
            postId={post.id}
            user={user}
            incrementCommentCount={() => incrementCommentCountInUI(post.id)}
          />
        </Box>
      ))}
    </Box>
  );
};

export default Feed;
