import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ListSubheader,
  ListItemText,
  Chip,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  IconButton,
  Avatar,
} from "@mui/material";
import { collection, addDoc } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { db, storage, auth } from "../firebaseConfig"; // Ensure firebaseConfig exports auth
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DoneIcon from "@mui/icons-material/Done";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import data from "../data.json"; // Adjust the path based on your project structure

const { professions, skillSets } = data; // Destructure data

const cities = [
  { name: "ירושלים", lat: 31.7683, lng: 2137 },
  { name: "תל אביב", lat: 32.0853, lng: 34.7818 },
  { name: "חיפה", lat: 32.794, lng: 34.9896 },
  { name: "ראשון לציון", lat: 31.973, lng: 34.7925 },
  { name: "פתח תקווה", lat: 32.084, lng: 34.8878 },
  { name: "אשדוד", lat: 31.8014, lng: 34.643 },
  { name: "נתניה", lat: 32.3215, lng: 34.8532 },
  { name: "באר שבע", lat: 31.2518, lng: 34.7913 },
  { name: "חולון", lat: 32.0158, lng: 34.7874 },
  { name: "בני ברק", lat: 32.084, lng: 34.8352 },
];

const cleanFormData = (data) => {
  const cleanedData = {};
  Object.keys(data).forEach((key) => {
    if (data[key] !== "") {
      cleanedData[key] = data[key];
    }
  });
  return cleanedData;
};
const AddMe = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    profession: "",
    skills: [],
    linkedin: "",
    github: "",
    website: "",
    location: "",
    level: "",
    bio: "",
    whatsapp: "",
    hasExperience: "",
    experienceYears: "",
    mailJobOpportunities: true,
    mailCareerOpportunities: true,
  });
  const [profileImage, setProfileImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [cvFile, setCvFile] = useState(null);
  const [cvFileName, setCvFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Ensure the name is not empty
    if (name) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  // Get relevant skills based on profession
  const getSkillsByProfession = () => {
    if (formData.profession && skillSets[formData.profession]) {
      return skillSets[formData.profession];
    }
    return []; // Return empty array if no profession is selected
  };
  const handleSkillsChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 5) {
      setFormData((prevData) => ({
        ...prevData,
        skills: typeof value === "string" ? value.split(",") : value,
      }));
    }
  };

  const handleCityChange = (e) => {
    const selectedCity = cities.find((city) => city.name === e.target.value);
    if (selectedCity) {
      setFormData((prevData) => ({
        ...prevData,
        location: selectedCity.name,
        lat: selectedCity.lat,
        lng: selectedCity.lng,
      }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setImagePreview(URL.createObjectURL(file));
      setUploadDone(false);
    }
  };

  const handleCvChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCvFile(file);
      setCvFileName(file.name);
    }
  };
  const validateFormData = (data, profileImage, cvFile) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // LinkedIn validation
    const linkedInRegex = /^https:\/\/www\.linkedin\.com\/.+$/;

    // GitHub validation
    const gitHubRegex = /^https:\/\/github\.com\/.+$/;

    // Website validation (optional field)
    const websiteRegex = /^https?:\/\/.+$/;

    // Validate CV file type
    const isCvPdf = cvFile && cvFile.type === "application/pdf";

    let errors = {};

    // Check for required fields (except for optional ones like website, github, and whatsapp)
    if (!data.name) errors.name = "Name is required";
    if (!data.email || !emailRegex.test(data.email))
      errors.email = "Invalid email address";
    if (!data.password || data.password.length < 6)
      errors.password = "Password must be at least 6 characters";
    if (!data.profession) errors.profession = "Profession is required";
    // if (!data.skills.length)
    //   errors.skills = "At least one skill must be selected";
    // if (!data.linkedin || !linkedInRegex.test(data.linkedin))
    //   errors.linkedin =
    //     'LinkedIn URL must start with "https://www.linkedin.com/"';

    // GitHub (if provided) must match the GitHub URL pattern
    // if (data.github && !gitHubRegex.test(data.github))
    //   errors.github = 'GitHub URL must start with "https://github.com/"';

    // Website (if provided) must be a valid URL
    // if (data.website && !websiteRegex.test(data.website))
    //   errors.website = 'Website URL must start with "https://" or "http://"';

    // Validate profile image
    // if (!profileImage) errors.profileImage = "Profile image is required";

    // Validate CV (must be PDF)
    // if (!cvFile || !isCvPdf)
    //   errors.cvFile = "CV must be uploaded and must be a PDF file";

    // Location is required
    if (!data.location) errors.location = "Location is required";

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Format WhatsApp number before submission
    let formattedWhatsApp = formData.whatsapp;

    // If the WhatsApp number starts with '0', replace it with '972'
    if (formattedWhatsApp.startsWith("0")) {
      formattedWhatsApp = `972${formattedWhatsApp.slice(1)}`;
    }

    // Add formatted WhatsApp number to formData
    const formattedFormData = {
      ...formData,
      whatsapp: formattedWhatsApp,
    };

    // Validate the form data before submission
    const validationErrors = validateFormData(
      formattedFormData,
      profileImage,
      cvFile
    );

    if (Object.keys(validationErrors).length > 0) {
      alert("אופס.. וודא שמילית את כל הפרטים והם נכונים");
      console.log("Validation Errors:", validationErrors);
      return;
    }

    // Proceed with form submission if there are no validation errors
    setLoading(true);

    try {
      // Create a new user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formattedFormData.email,
        formattedFormData.password
      );
      const user = userCredential.user;

      // Send an email verification to the user
      await sendEmailVerification(user);

      // Upload the profile image to Firebase Storage (if profile image exists)
      let profileImageUrl = "";
      if (profileImage) {
        const imageStorageRef = ref(
          storage,
          `profileImages/${profileImage.name}`
        );
        await uploadBytes(imageStorageRef, profileImage);
        profileImageUrl = await getDownloadURL(imageStorageRef);
      }

      // Upload the CV file to Firebase Storage (if CV exists)
      let cvUrl = "";
      if (cvFile) {
        const cvStorageRef = ref(storage, `cvFiles/${cvFile.name}`);
        await uploadBytes(cvStorageRef, cvFile);
        cvUrl = await getDownloadURL(cvStorageRef);
      }

      // Clean the form data before saving it to Firestore
      const cleanedFormData = cleanFormData({
        ...formattedFormData,
        profilePicture: profileImageUrl,
        cvUrl: cvUrl,
        userID: user.uid,
        emailVerified: false,
        hasExperience: formData.hasExperience || false,
        experienceYears: formData.experienceYears || 0,
        visible: true, // Initially set user as  visible
      });

      // Add the cleaned data to Firestore under the "developers" collection
      await addDoc(collection(db, "developers"), cleanedFormData);

      // Optionally, track the email in a separate "emails" collection
      await addDoc(collection(db, "emails"), {
        email: formattedFormData.email,
        userID: user.uid,
        verified: false, // Start as unverified
      });

      // Set the upload completion status
      setUploadDone(true);

      // Navigate to the email verification page
      navigate(`/`);
      // navigate(`/email-verification/${user.uid}`);
    } catch (error) {
      // Handle any errors that occur during the registration process
      console.error("Error during registration:", error);
      alert("אופס!! הייתה בעיה בתקשורת");
    } finally {
      // Stop the loading indicator regardless of success or failure
      setLoading(false);
    }
  };

  return (
    <Container dir="rtl" sx={{ padding: 3 }}>
      <Container
        dir="rtl"
        sx={{
          bgcolor: "#fdf3e3",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
          position: "relative",
          borderLeft: "22px solid #b5651d",
          borderRadius: "10px",
          boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            width: "100%",
            borderRadius: 4,
            maxWidth: "700px",
            padding: 2,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "CustomFont, serif",
              textAlign: "center",
              color: "#b5651d",
            }}
          >
            הצטרפות לDevLink
          </Typography>

          <Typography variant="h6">נתחיל עם נתונים יבשים:</Typography>

          <TextField
            label="מה שמך?"
            name="name"
            fullWidth
            required
            placeholder="בוב כהן"
            onChange={handleChange}
            value={formData.name}
            autoComplete="off"
            sx={{ bgcolor: "#fdf3e3" }}
          />
          <TextField
            autoComplete="off"
            label="מייל"
            name="email"
            fullWidth
            placeholder="bobcohen@gmail.com"
            required
            type="email"
            onChange={handleChange}
            value={formData.email}
            sx={{ bgcolor: "#fdf3e3" }}
          />
          <TextField
            autoComplete="off"
            label="סיסמה"
            placeholder="מעל 6 תווים"
            name="password"
            fullWidth
            required
            type="password"
            onChange={handleChange}
            value={formData.password}
            sx={{ bgcolor: "#fdf3e3" }}
          />
          <Typography variant="h6">קריירה:</Typography>

          <FormControl fullWidth>
            <InputLabel>תחום</InputLabel>
            <Select
              value={formData.profession}
              onChange={handleChange}
              name="profession"
              label="תחום"
              fullWidth
              required
              sx={{ bgcolor: "#fdf3e3" }}
            >
              {professions.map((profession) => (
                <MenuItem key={profession} value={profession}>
                  {profession}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Dynamic Skills Selection */}
          {/* Skills Multi-Select */}
          <FormControl fullWidth>
            <InputLabel>שפות (בחר עד 5)</InputLabel>
            <Select
              multiple
              value={formData.skills}
              onChange={handleSkillsChange}
              input={
                <OutlinedInput
                  label={
                    formData.profession
                      ? `${formData.profession} Skills`
                      : "Skills"
                  }
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((skill) => (
                    <Chip key={skill} label={skill} />
                  ))}
                </Box>
              )}
              sx={{
                borderRadius: "10px",
                bgcolor: "background.paper",
              }}
            >
              {Object.keys(skillSets).map((profession) => [
                <ListSubheader key={`${profession}-header`}>
                  {profession} Skills
                </ListSubheader>,
                ...skillSets[profession].map((skill) => (
                  <MenuItem key={skill} value={skill}>
                    <Checkbox checked={formData.skills.indexOf(skill) > -1} />
                    <ListItemText primary={skill} />
                  </MenuItem>
                )),
              ])}
            </Select>
          </FormControl>
          <TextField
            autoComplete="off"
            label="משפט על עצמך..."
            name="bio"
            fullWidth
            placeholder="ספר לנו מה אתה מביא כמפתח.."
            multiline
            inputProps={{ maxLength: 100 }} // Set maximum characters to 200
            minRows={3}
            onChange={handleChange}
            value={formData.bio}
            sx={{ bgcolor: "#fdf3e3" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.hasExperience}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    hasExperience: e.target.checked,
                  }))
                }
                name="hasExperience"
              />
            }
            label="יש לך ניסיון?"
          />

          {formData.hasExperience && (
            <TextField
              label="כמה שנים?"
              name="experienceYears"
              type="number"
              fullWidth
              required
              onChange={handleChange}
              value={formData.experienceYears}
            />
          )}
          <Typography variant="h6">קישורים חיצוניים:</Typography>
          <TextField
            autoComplete="off"
            label="אתר (קישור לאתר שלכם)"
            name="website"
            fullWidth
            placeholder="https://www.bob.com"
            onChange={handleChange}
            value={formData.website}
            sx={{ bgcolor: "#fdf3e3" }}
          />
          <TextField
            autoComplete="off"
            label="לינקדאין (קישור ללינקדאין שלכם)"
            name="linkedin"
            fullWidth
            placeholder="https://www.linkedin.com/in/bob"
            onChange={handleChange}
            value={formData.linkedin}
            sx={{ bgcolor: "#fdf3e3" }}
          />
          <TextField
            label="גיט (קישור לגיט שלכם)"
            name="github"
            fullWidth
            placeholder="https://github.com/bob"
            value={formData.github}
            onChange={handleChange}
            sx={{ marginTop: 2 }}
          />

          <TextField
            type="tel"
            label="וואצאפ"
            name="whatsapp"
            placeholder="0544626555"
            inputProps={{
              pattern: "[0-9+ ]*", // Only allows numbers, plus, and spaces
              title: "Only numbers and the plus symbol are allowed",
            }}
            fullWidth
            value={formData.whatsapp}
            onChange={handleChange}
            sx={{ marginTop: 2 }}
          />

          <FormControl fullWidth>
            <InputLabel>מיקום</InputLabel>
            <Select
              value={formData.location}
              onChange={handleCityChange}
              name="location"
              label="מיקום"
              fullWidth
              required
              sx={{ bgcolor: "#fdf3e3" }}
            >
              {cities.map((city) => (
                <MenuItem key={city.name} value={city.name}>
                  {city.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.mailJobOpportunities}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    mailJobOpportunities: e.target.checked,
                  }))
                }
                name="mailJobOpportunities"
              />
            }
            label="מעוניין לקבל התראות לאופציות תעסוקה רלוונטיות ?"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.mailCareerOpportunities}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    mailCareerOpportunities: e.target.checked,
                  }))
                }
                name="mailCareerOpportunities"
              />
            }
            label="מעוניין להירשם לניוזלטר שלנו ? (חדשות טק\מאמרים\קריירה)"
          />
          {/* Image Upload Area */}
          <Box sx={{ textAlign: "center" }}>
            {imagePreview && (
              <Avatar
                src={imagePreview}
                alt="Profile Preview"
                sx={{ width: 100, height: 100, margin: "0 auto", mb: 2 }}
              />
            )}
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              sx={{
                borderRadius: "20px",
                backgroundColor: "#b5651d",
                color: "#fff",
                p: 1,
                "&:hover": { backgroundColor: theme.palette.primary.dark },
              }}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleImageChange}
              />
              {uploadDone ? (
                <DoneIcon sx={{ fontSize: 40 }} />
              ) : (
                <CloudUploadIcon sx={{ fontSize: 40 }} />
              )}
            </IconButton>
            <Typography variant="body1" sx={{ mt: 2, color: "text.primary" }}>
              {uploadDone ? "הועלה בהצלחה" : " תמונת פרופיל"}
            </Typography>
          </Box>

          {/* CV Upload */}
          <Box
            sx={{
              textAlign: "center",
              mt: 2,
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <input
              accept=".pdf,.doc,.docx"
              type="file"
              onChange={handleCvChange}
              hidden
              id="cv-upload"
            />
            <label htmlFor="cv-upload">
              <Button
                variant="contained"
                component="span"
                sx={{ backgroundColor: "#b5651d", padding: 1 }}
              >
                קורות חיים (PDF)
              </Button>
            </label>
            <Typography
              variant="body1"
              sx={{ color: "text.primary", marginBottom: "0" }}
            >
              {cvFileName || "לא נבחר קובץ"}
            </Typography>
          </Box>

          {/* Loader and Submit Button */}
          {loading ? (
            <Box sx={{ textAlign: "center", my: 2 }}>
              <CircularProgress />
              <Typography sx={{ color: "text.primary", mt: 2 }}>
                מעלה...
              </Typography>
            </Box>
          ) : (
            <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                borderRadius: "20px",
                padding: "10px",
                backgroundColor: "#b5651d",
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              הרשמה
            </Button>
          )}
        </Box>
      </Container>
    </Container>
  );
};

export default AddMe;
