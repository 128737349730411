import { collection, addDoc, updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";

// Function to add a new post
export const addPost = async (postData) => {
  const { content, email, userId, profilePicture, username } = postData;

  if (!userId || !email) throw new Error("User not authenticated");

  const newPostData = {
    content,
    timestamp: new Date(),
    userId, // Store user ID (Firebase Auth ID)
    username: username || "User", // Optional: Store user's display name
    userProfilePicture: profilePicture || "/userAvatar.jpg", // Store profile picture or default
    email,
    likes: 0,
    likedByCurrentUser: false,
    commentsCount: 0,
    likedBy: [],
  };

  // Add the post to Firestore
  const docRef = await addDoc(collection(db, "posts"), newPostData);
  return { id: docRef.id, ...newPostData };
};

export const likePost = async (postId, currentlyLiked, userId) => {
  const postRef = doc(db, "posts", postId);
  const postSnap = await getDoc(postRef);

  if (postSnap.exists()) {
    const postData = postSnap.data();
    let newLikes = postData.likes;
    let likedBy = postData.likedBy || []; // Get the list of users who liked the post

    if (currentlyLiked) {
      // Unlike the post
      likedBy = likedBy.filter((id) => id !== userId);
      newLikes--;
    } else {
      // Like the post
      likedBy.push(userId);
      newLikes++;
    }

    await updateDoc(postRef, {
      likes: newLikes,
      likedBy: likedBy, // Update the likedBy array
    });
  } else {
    throw new Error("Post does not exist");
  }
};
