import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import {
  Container,
  Button,
  Box,
  Typography,
  Modal,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
  TextField,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

const containerStyle = {
  width: "100%",
  height: "600px",
};

// Center of Israel
const center = {
  lat: 31.0461,
  lng: 34.8516,
};

// Define the bounds of Israel
const israelBounds = {
  north: 33.3322,
  south: 29.4961,
  west: 34.2675,
  east: 35.8967,
};

const MapView = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAK717qjbmX0X_B5ZQaP-nJa6Z4_6OW1IM", // Add your Google Maps API key
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screens
  const [developers, setDevelopers] = useState([]);
  const [cityDevelopers, setCityDevelopers] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // For filtering developers

  useEffect(() => {
    const fetchDevelopers = async () => {
      const querySnapshot = await getDocs(collection(db, "developers"));

      const devs = querySnapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        .filter((dev) => dev.visible === true);

      const groupedByCity = devs.reduce((acc, dev) => {
        if (!acc[dev.location]) acc[dev.location] = [];
        acc[dev.location].push(dev);
        return acc;
      }, {});

      setDevelopers(devs);
      setCityDevelopers(groupedByCity);
    };
    fetchDevelopers();
  }, []);

  const handleOpenModal = (city) => {
    setSelectedCity(city);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedCity(null);
  };

  const filteredDevelopers = selectedCity
    ? cityDevelopers[selectedCity].filter((dev) =>
        dev.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <Container
      sx={{ bgcolor: "background.default", minHeight: "100vh", padding: 2 }}
    >
      <Typography
        variant="h4"
        sx={{
          my: 2,
          fontFamily: "CustomFont, serif",
          textAlign: "center",
          color: "#b5651d",
        }}
      >
        מפה
      </Typography>

      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={1}
          options={{
            restriction: {
              latLngBounds: israelBounds, // Restrict map to Israel
              strictBounds: true, // Prevent user from moving outside the bounds
            },
          }}
        >
          {Object.keys(cityDevelopers).map((city, index) => {
            const cityDev = cityDevelopers[city][0];
            const devCount = cityDevelopers[city].length;

            return (
              <Marker
                key={index}
                position={{ lat: cityDev.lat, lng: cityDev.lng }}
                onClick={() => setSelectedMarker(city)}
                icon={{
                  url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
                    `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                      <circle cx="20" cy="20" r="20" fill="#b5651d" />
                      <text x="50%" y="50%" font-size="14" fill="white" text-anchor="middle" dy=".3em">${devCount}</text>
                    </svg>`
                  )}`,
                  scaledSize: { width: 40, height: 40 },
                }}
              >
                {selectedMarker === city && (
                  <InfoWindow
                    position={{ lat: cityDev.lat, lng: cityDev.lng }}
                    onCloseClick={() => setSelectedMarker(null)}
                  >
                    <Box
                      sx={{
                        backgroundColor: "white",
                        color: "black",
                        borderRadius: 8,
                        padding: 1,
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        {city}
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        {devCount} מפתחים
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => handleOpenModal(city)}
                        sx={{
                          backgroundColor: "black",
                          color: "white",
                          "&:hover": { backgroundColor: "#1e1e1e" },
                        }}
                      >
                        צפה במפתחים
                      </Button>
                    </Box>
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
        </GoogleMap>
      )}

      <Modal open={open} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "90%" : 500, // Make width responsive
            bgcolor: "background.paper",
            borderRadius: 4,
            p: isMobile ? 2 : 4, // Adjust padding for mobile
            boxShadow: 24,
            color: "text.primary",
          }}
        >
          <Typography variant="h6" sx={{ textAlign: "center", mb: 2 }}>
            מפתחים ב{selectedCity}
          </Typography>

          <TextField
            fullWidth
            placeholder="חיפוש..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />

          <List>
            {filteredDevelopers.map((dev, index) => (
              <ListItem
                sx={{ cursor: "pointer" }}
                key={index}
                button
                onClick={() => navigate(`/profile/${dev.id}`)}
              >
                <ListItemAvatar>
                  <Avatar alt={dev.name} src={dev.profilePicture} />
                </ListItemAvatar>
                <ListItemText primary={dev.name} secondary={dev.profession} />
              </ListItem>
            ))}
          </List>

          <Button
            onClick={handleCloseModal}
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              borderRadius: "10px",
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            סגור
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default MapView;
