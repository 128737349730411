import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Badge,
} from "@mui/material";

const UserList = ({ setActiveUser, currentUser }) => {
  const [users, setUsers] = useState([]); // Initialize users as an empty array
  const [unreadMessages, setUnreadMessages] = useState({}); // Store unread messages count

  // Fetch all users (developers) from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      const usersSnapshot = await getDocs(collection(db, "developers"));
      const usersList = usersSnapshot.docs.map((doc) => doc.data());
      setUsers(usersList); // Set users list in state
    };

    fetchUsers();
  }, []);

  // Listen for unread messages in real-time
  useEffect(() => {
    if (currentUser) {
      const chatsQuery = query(
        collection(db, "messages"),
        where("receiverId", "==", currentUser.userID), // Query messages for the current user
        where("read", "==", false) // Only get unread messages
      );

      const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
        const unreadCount = {};
        console.log(snapshot);
        snapshot.forEach((doc) => {
          const chatId = doc.data().chatId;

          unreadCount[chatId] = (unreadCount[chatId] || 0) + 1; // Increment unread count per chatId
        });
        console.log(unreadCount);

        setUnreadMessages(unreadCount); // Set unread messages count per chat
      });

      return () => unsubscribe(); // Clean up listener on unmount
    }
  }, [currentUser]);

  // Ensure users is an array before applying filter
  const filteredUsers = Array.isArray(users)
    ? users.filter((user) => user.email !== currentUser?.email)
    : [];
  // Generate a unique chatId based on the user IDs
  const generateChatId = (a, b) => {
    return [a, b].sort().join("");
  };

  // Handle user click to set active user for chat
  const handleUserClick = (userId) => {
    const selectedUser = filteredUsers.find((user) => user.userID === userId);
    setActiveUser(selectedUser);
  };

  return (
    <Box>
      <List>
        {filteredUsers.map((user) => (
          <ListItem
            key={user.userID}
            button
            onClick={() => handleUserClick(user.userID)}
          >
            <ListItemAvatar>
              <Avatar
                alt={user.name}
                src={user.profilePicture || "/defaultAvatar.jpg"}
              />
            </ListItemAvatar>
            <ListItemText
              primary={user.name}
              secondary={
                unreadMessages[user.userID] > 0
                  ? `${unreadMessages[user.userID]} unread`
                  : user.profession || "Available"
              }
            />
            {unreadMessages[generateChatId(currentUser.userID, user.userID)] >
              0 && (
              <Badge
                badgeContent={
                  unreadMessages[
                    generateChatId(currentUser.userID, user.userID)
                  ]
                }
                color="error"
              />
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default UserList;
