import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  ListSubheader,
  Checkbox,
  ListItemText,
  FormControl,
  Chip,
  OutlinedInput,
  IconButton,
  Avatar,
} from "@mui/material";
import { query, where, getDocs, collection } from "firebase/firestore"; // Add Firestore query imports
import { doc, updateDoc } from "firebase/firestore";
import { auth, db, storage } from "../firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DoneIcon from "@mui/icons-material/Done";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth"; // Import onAuthStateChanged from Firebase
import data from "../data.json"; // Adjust the path based on your project structure
import BusinessCardModal from "./BusinessCardModal";

const { skillsList, professions, frontEndSkills, backEndSkills, skillSets } =
  data; // Destructure data

const cities = [
  { name: "ירושלים", lat: 31.7683, lng: 2137 },
  { name: "תל אביב", lat: 32.0853, lng: 34.7818 },
  { name: "חיפה", lat: 32.794, lng: 34.9896 },
  { name: "ראשון לציון", lat: 31.973, lng: 34.7925 },
  { name: "פתח תקווה", lat: 32.084, lng: 34.8878 },
  { name: "אשדוד", lat: 31.8014, lng: 34.643 },
  { name: "נתניה", lat: 32.3215, lng: 34.8532 },
  { name: "באר שבע", lat: 31.2518, lng: 34.7913 },
  { name: "חולון", lat: 32.0158, lng: 34.7874 },
  { name: "בני ברק", lat: 32.084, lng: 34.8352 },
];

const Profile = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    profession: "",
    skills: [],
    linkedin: "",
    github: "",
    website: "",
    location: "",
    level: "",
    hasExperience: "",
    experienceYears: "",
    bio: "",
    mailJobOpportunities: true,
    mailCareerOpportunities: true,
  });
  const [profileImage, setProfileImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [cvFile, setCvFile] = useState(null);
  const [cvFileName, setCvFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is logged in, proceed to fetch the profile
        const fetchUserProfile = async () => {
          setLoading(true);
          // Query Firestore to find the document where email matches the current user's email
          const q = query(
            collection(db, "developers"),
            where("email", "==", user.email)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const docSnap = querySnapshot.docs[0]; // Get the first matching document
            const userData = docSnap.data();
            setFormData({ ...userData });
            setImagePreview(userData.profilePicture);
            setCvFileName(
              userData.cvUrl ? userData.cvUrl.split("/").pop() : ""
            ); // Extract file name from cvUrl
            setUserId(docSnap.id); // Store the document ID for updating later
          } else {
            console.log("No such document!"); // No document found for the user's email
          }
          setLoading(false);
        };

        fetchUserProfile();
      } else {
        // User is not logged in, redirect to login page
        navigate("/login");
      }
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  // Function to dynamically fetch skills based on selected profession
  const getSkillsByProfession = () => {
    const selectedProfession = formData.profession;
    if (selectedProfession && skillSets[selectedProfession]) {
      return skillSets[selectedProfession];
    }
    return []; // Return empty array if no profession is selected or no skills found
  };
  const handleSkillsChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 5) {
      setFormData((prevData) => ({
        ...prevData,
        skills: typeof value === "string" ? value.split(",") : value,
      }));
    }
  };
  const trimFileName = (fileName) => {
    if (!fileName) return "";

    // Remove query parameters if they exist
    const cleanFileName = fileName.split("?")[0];

    const parts = cleanFileName.split(".");
    const extension = parts.pop(); // Get the file extension
    const baseName = parts.join("."); // Get the base file name without the extension

    if (baseName.length > 10) {
      // Trim the middle of the base name
      return `${baseName.substring(0, 3)}****${baseName.substring(
        baseName.length - 3
      )}.${extension}`;
    }

    return `${baseName}.${extension}`; // If the base name is short, return as is
  };

  const handleCityChange = (e) => {
    const selectedCity = cities.find((city) => city.name === e.target.value);
    if (selectedCity) {
      setFormData((prevData) => ({
        ...prevData,
        location: selectedCity.name,
        lat: selectedCity.lat,
        lng: selectedCity.lng,
      }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setImagePreview(URL.createObjectURL(file));
      setUploadDone(false);
    }
  };

  const handleCvChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCvFile(file);
      setCvFileName(file.name);
    }
  };
  const validateProfileFormData = (data, profileImage, cvFile) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const linkedInRegex = /^https:\/\/www\.linkedin\.com\/.+$/;
    const gitHubRegex = /^https:\/\/(www\.)?github\.com\/.+$/;
    const websiteRegex = /^https?:\/\/.+$/;
    const whatsappRegex = /^[0-9+ ]*$/;
    const isCvPdf = cvFile && cvFile.type === "application/pdf";

    let errors = {};

    // Check required fields (excluding optional fields like github, whatsapp, website)
    if (!data.name) errors.name = "Name is required";
    if (!data.email || !emailRegex.test(data.email))
      errors.email = "Invalid email address";
    if (!data.profession) errors.profession = "Profession is required";
    // if (!data.skills.length)
    //   errors.skills = "At least one skill must be selected";

    // LinkedIn validation only if provided
    if (data.linkedin && !linkedInRegex.test(data.linkedin))
      errors.linkedin =
        'LinkedIn URL must start with "https://www.linkedin.com/"';

    // GitHub validation only if provided
    if (data.github && !gitHubRegex.test(data.github))
      errors.github = 'GitHub URL must start with "https://github.com/"';

    // Website validation only if provided
    if (data.website && !websiteRegex.test(data.website))
      errors.website = 'Website URL must start with "https://" or "http://"';

    // WhatsApp validation only if provided
    if (data.whatsapp && !whatsappRegex.test(data.whatsapp))
      errors.whatsapp = 'WhatsApp must contain only numbers, spaces, or "+"';

    // // Validate profile image is uploaded if it's required
    // if (!profileImage && !data.profilePicture)
    //   errors.profileImage = "Profile image is required";

    // Validate CV (must be PDF) if it's required
    // if (!cvFile && !data.cvUrl)
    //   errors.cvFile = "CV must be uploaded and must be a PDF file";
    if (cvFile && !isCvPdf) errors.cvFile = "CV must be in PDF format";

    // Location is required
    if (!data.location) errors.location = "Location is required";

    return errors;
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    // Format WhatsApp number before updating
    let formattedWhatsApp = formData.whatsapp || ""; // Ensure whatsapp is defined as an empty string if undefined
    if (formattedWhatsApp.startsWith("0")) {
      formattedWhatsApp = `972${formattedWhatsApp.slice(1)}`;
    }

    // Add formatted WhatsApp number to formData
    const updatedFormData = {
      ...formData,
      whatsapp: formattedWhatsApp,
    };

    // Validate the form data before submission
    const validationErrors = validateProfileFormData(
      updatedFormData,
      profileImage,
      cvFile
    );

    if (Object.keys(validationErrors).length > 0) {
      alert("אופס.. וודא שמילית את כל הפרטים והם נכונים");
      console.log("Validation Errors:", validationErrors);
      return;
    }

    // Proceed with form submission if there are no validation errors
    setLoading(true);

    try {
      const updates = { ...updatedFormData }; // Collect updates

      // Upload profile image if changed
      if (profileImage) {
        const imageStorageRef = ref(
          storage,
          `profileImages/${profileImage.name}`
        );
        await uploadBytes(imageStorageRef, profileImage);
        const profileImageUrl = await getDownloadURL(imageStorageRef);
        updates.profilePicture = profileImageUrl;
      }

      // Upload CV file if changed
      if (cvFile) {
        const cvStorageRef = ref(storage, `cvFiles/${cvFile.name}`);
        await uploadBytes(cvStorageRef, cvFile);
        const cvUrl = await getDownloadURL(cvStorageRef);
        updates.cvUrl = cvUrl;
      }

      // Update the developer data in Firestore using the document ID
      const docRef = doc(db, "developers", userId); // Use the stored document ID
      await updateDoc(docRef, updates);

      setUploadDone(true);
      navigate("/"); // Redirect after successful update
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("There was an error updating your profile. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", marginTop: 5 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container dir="rtl" sx={{ padding: 3 }}>
      <Container
        dir="rtl"
        sx={{
          bgcolor: "#fdf3e3",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
          position: "relative",
          borderLeft: "22px solid #b5651d",
          borderRadius: "10px",
          boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
        }}
      >
        <Box
          component="form"
          onSubmit={handleUpdate}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            width: "100%",
            borderRadius: 4,
            maxWidth: "700px",
            padding: 2,
          }}
        >
          {imagePreview && (
            <Avatar
              src={imagePreview}
              alt="Profile Preview"
              sx={{ width: 100, height: 100, margin: "0 auto", mb: 0 }}
            />
          )}
          <Typography
            variant="h4"
            sx={{
              fontFamily: "CustomFont, serif",
              textAlign: "center",
              color: "#b5651d",
            }}
          >
            <Box sx={{ paddingBottom: 2 }}>
              <BusinessCardModal
                name={formData.name} // Developer's name
                profession={formData.profession} // Profession
                profilePicture={formData.profilePicture} // Profile picture URL
                linkedin={formData.linkedin} // LinkedIn URL
                github={formData.github} // GitHub URL
                email={formData.email} // Email address
                location={formData.location} // Location
                bio={formData.bio} // Bio description
                skills={formData.skills} // Array of skills
                profileUrl={"https://www.devlink.co.il/profile/" + userId} // CV or profile link
                whatsapp={formData.whatsapp}
              />{" "}
            </Box>
            ברוך השב, {formData.name}
          </Typography>
          <TextField
            label="מה שמך?"
            name="name"
            fullWidth
            required
            onChange={handleChange}
            value={formData.name}
            autoComplete="off"
            sx={{ bgcolor: "#fdf3e3" }}
          />
          <TextField
            autoComplete="off"
            label="מייל"
            name="email"
            fullWidth
            required
            type="email"
            onChange={handleChange}
            value={formData.email}
            sx={{ bgcolor: "#fdf3e3" }}
            disabled
          />
          <Typography variant="h6">קריירה:</Typography>

          <FormControl fullWidth>
            <InputLabel>תחום</InputLabel>
            <Select
              value={formData.profession}
              onChange={handleChange}
              name="profession"
              label="תחום"
              fullWidth
              required
              sx={{ bgcolor: "#fdf3e3" }}
            >
              {professions.map((profession) => (
                <MenuItem key={profession} value={profession}>
                  {profession}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Skills Multi-Select */}
          <FormControl fullWidth>
            <InputLabel>שפות (בחר עד 5)</InputLabel>
            <Select
              multiple
              value={formData.skills}
              onChange={handleSkillsChange}
              input={
                <OutlinedInput
                  label={
                    formData.profession
                      ? `${formData.profession} Skills`
                      : "Skills"
                  }
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((skill) => (
                    <Chip key={skill} label={skill} />
                  ))}
                </Box>
              )}
              sx={{
                borderRadius: "10px",
                bgcolor: "background.paper",
              }}
            >
              {Object.keys(skillSets).map((profession) => [
                <ListSubheader key={`${profession}-header`}>
                  {profession} Skills
                </ListSubheader>,
                ...skillSets[profession].map((skill) => (
                  <MenuItem key={skill} value={skill}>
                    <Checkbox checked={formData.skills.indexOf(skill) > -1} />
                    <ListItemText primary={skill} />
                  </MenuItem>
                )),
              ])}
            </Select>
          </FormControl>

          <TextField
            autoComplete="off"
            label="משפט על עצמך..."
            name="bio"
            fullWidth
            multiline
            minRows={3}
            onChange={handleChange}
            value={formData.bio}
            sx={{ bgcolor: "#fdf3e3" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.hasExperience}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    hasExperience: e.target.checked,
                  }))
                }
                name="hasExperience"
              />
            }
            label="יש לך ניסיון?"
          />

          {formData.hasExperience && (
            <TextField
              label="כמה שנים?"
              name="experienceYears"
              type="number"
              fullWidth
              required
              onChange={handleChange}
              value={formData.experienceYears}
            />
          )}
          <Typography variant="h6">קישורים חיצוניים:</Typography>

          <TextField
            autoComplete="off"
            label="אתר (קישור לאתר שלכם)"
            name="website"
            fullWidth
            placeholder="https://www.bob.com"
            onChange={handleChange}
            value={formData.website}
            sx={{ bgcolor: "#fdf3e3" }}
          />
          <TextField
            autoComplete="off"
            label="לינקדאין (קישור ללינקדאין שלכם)"
            name="linkedin"
            fullWidth
            placeholder="https://www.linkedin.com/in/bob"
            onChange={handleChange}
            value={formData.linkedin}
            sx={{ bgcolor: "#fdf3e3" }}
          />
          <TextField
            label="גיט (קישור לגיט שלכם)"
            name="github"
            fullWidth
            placeholder="https://www.github.com/bob"
            value={formData.github}
            onChange={handleChange}
            sx={{ marginTop: 2 }}
          />

          <TextField
            type="tel"
            label="וואצאפ"
            name="whatsapp"
            placeholder="0544626555"
            inputProps={{
              pattern: "[0-9+ ]*", // Only allows numbers, plus, and spaces
              title: "Only numbers and the plus symbol are allowed",
            }}
            fullWidth
            value={formData.whatsapp}
            onChange={handleChange}
            sx={{ marginTop: 2 }}
          />

          <FormControl fullWidth>
            <InputLabel>מיקום</InputLabel>
            <Select
              value={formData.location}
              onChange={handleCityChange}
              name="location"
              label="מיקום"
              fullWidth
              required
              sx={{ bgcolor: "#fdf3e3" }}
            >
              {cities.map((city) => (
                <MenuItem key={city.name} value={city.name}>
                  {city.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.mailJobOpportunities}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    mailJobOpportunities: e.target.checked,
                  }))
                }
                name="mailJobOpportunities"
              />
            }
            label="מעוניין לקבל התראות לאופציות תעסוקה רלוונטיות ?"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.mailCareerOpportunities}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    mailCareerOpportunities: e.target.checked,
                  }))
                }
                name="mailCareerOpportunities"
              />
            }
            label="מעוניין להירשם לניוזלטר שלנו ? (חדשות טק\מאמרים\קריירה)"
          />
          {/* Image Upload Area */}
          <Box sx={{ textAlign: "center" }}>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              sx={{
                borderRadius: "20px",
                backgroundColor: "#b5651d",
                color: "#fff",
                p: 1,
                "&:hover": { backgroundColor: theme.palette.primary.dark },
              }}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleImageChange}
              />
              {uploadDone ? (
                <DoneIcon sx={{ fontSize: 40 }} />
              ) : (
                <CloudUploadIcon sx={{ fontSize: 40 }} />
              )}
            </IconButton>
            <Typography variant="body1" sx={{ mt: 2, color: "text.primary" }}>
              {uploadDone ? "הועלה בהצלחה" : "העלאת תמונה חדשה"}
            </Typography>
          </Box>

          {/* CV Upload */}
          <Box
            sx={{
              textAlign: "center",
              mt: 2,
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <input
              accept=".pdf,.doc,.docx"
              type="file"
              onChange={handleCvChange}
              hidden
              id="cv-upload"
            />
            <label htmlFor="cv-upload">
              <Button
                variant="contained"
                component="span"
                sx={{ backgroundColor: "#b5651d", padding: 1 }}
              >
                קורות חיים (PDF)
              </Button>
            </label>
            <Typography
              variant="body1"
              sx={{ color: "text.primary", marginBottom: "0" }}
            >
              {cvFileName ? trimFileName(cvFileName) : "לא נבחר קובץ"}
            </Typography>
          </Box>

          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              borderRadius: "20px",
              padding: "10px",
              backgroundColor: "#b5651d",
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            עדכן פרופיל
          </Button>
        </Box>
      </Container>
    </Container>
  );
};

export default Profile;
