import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Box,
  Chip,
  IconButton,
  CircularProgress, // Import CircularProgress
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
  increment,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useTheme } from "@mui/material/styles"; // Import useTheme for theme

const Saved = () => {
  const [savedDevelopers, setSavedDevelopers] = useState([]);
  const [starredDevs, setStarredDevs] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const theme = useTheme(); // Access the theme

  useEffect(() => {
    // Get starred developers from localStorage
    const savedEmails =
      JSON.parse(localStorage.getItem("starredDevelopers")) || [];
    setStarredDevs(savedEmails);
  }, []);
  const getExperienceLevel = (developer) => {
    if (!developer.hasExperience) {
      return "Unexperienced";
    } else if (developer.experienceYears <= 5) {
      return "Junior";
    } else {
      return "Senior";
    }
  };
  useEffect(() => {
    const fetchSavedDevelopers = async () => {
      const savedEmails =
        JSON.parse(localStorage.getItem("starredDevelopers")) || [];

      if (savedEmails.length === 0) {
        setLoading(false); // No developers, stop loading
        return;
      }

      try {
        // Fetch developers whose emails are in the starred list
        const developersQuery = query(
          collection(db, "developers"),
          where("email", "in", savedEmails)
        );
        const querySnapshot = await getDocs(developersQuery);

        const developers = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSavedDevelopers(developers);
      } catch (error) {
        console.error("Error fetching saved developers: ", error);
      }

      setLoading(false); // Stop loading after fetching developers
    };

    fetchSavedDevelopers();
  }, []);

  const toggleStarDeveloper = async (email) => {
    let updatedStarredDevs;

    // Query Firestore to find the developer document by email
    const developersCollectionRef = collection(db, "developers");
    const q = query(developersCollectionRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("No matching developer found!");
      return; // Exit if no developer found
    }

    const devDocRef = querySnapshot.docs[0].ref;

    if (starredDevs.includes(email)) {
      // Remove the developer from starredDevs and savedDevelopers arrays
      updatedStarredDevs = starredDevs.filter((devEmail) => devEmail !== email);
      setSavedDevelopers((prevDevs) =>
        prevDevs.filter((dev) => dev.email !== email)
      );

      // Decrement starredCount in Firestore
      await updateDoc(devDocRef, {
        starredCount: increment(-1), // Decrement starredCount by 1
      });
    } else {
      // Add the developer to starredDevs (no change needed in savedDevelopers array)
      updatedStarredDevs = [...starredDevs, email];

      // Increment starredCount in Firestore
      await updateDoc(devDocRef, {
        starredCount: increment(1), // Increment starredCount by 1
      });
    }

    setStarredDevs(updatedStarredDevs);
    localStorage.setItem(
      "starredDevelopers",
      JSON.stringify(updatedStarredDevs)
    );
  };

  if (loading) {
    // Show loader while loading
    return (
      <Container sx={{ textAlign: "center", marginTop: 5 }}>
        <CircularProgress sx={{ color: theme.palette.primary.main }} />
        <Typography variant="h6" sx={{ marginTop: 2, color: "text.primary" }}>
          טוען...
        </Typography>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        bgcolor: "background.default",
        minHeight: "100vh",
        padding: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          my: 3,
          fontFamily: "CustomFont, serif", // Classic typography for titles
          textAlign: "center",
          color: "#b5651d",
        }}
      >
        המפתחים השמורים
      </Typography>

      {savedDevelopers.length === 0 ? (
        <Typography variant="h6" sx={{ marginTop: 5, color: "text.primary" }}>
          אין מפתחים שמורים.
        </Typography>
      ) : (
        <Grid container spacing={3} sx={{ maxWidth: "1200px", padding: 2 }}>
          {savedDevelopers.map((dev) => (
            <Grid item xs={12} sm={6} md={4} key={dev.id}>
              <Card
                sx={{
                  transition: "0.3s",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow: "0 20px 40px rgba(0,0,0,0.2)",
                  },
                  borderRadius: "10px",
                  boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
                  overflow: "hidden",
                  bgcolor: "#fdf3e3",
                  position: "relative",
                  borderLeft: "22px solid #b5651d",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                {/* Star Icon Button */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    padding: 1,
                    zIndex: "999",
                  }}
                >
                  <IconButton
                    onClick={() => toggleStarDeveloper(dev.email)}
                    color="primary"
                  >
                    {starredDevs.includes(dev.email) ? (
                      <StarIcon sx={{ color: "#FFD700" }} />
                    ) : (
                      <StarBorderIcon sx={{ color: "#FFD700" }} />
                    )}
                  </IconButton>
                </Box>

                {/* Developer Info */}
                <Link
                  to={`/profile/${dev.id}`}
                  style={{ textDecoration: "none", flexGrow: 1 }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0",
                      left: "-15px",
                      backgroundColor: "#b5651d",
                      color: "#fff",
                      padding: "5px 15px",
                      borderRadius: "0 0 10px 0",
                      fontSize: "12px",
                      fontFamily: "CustomFont",
                    }}
                  >
                    {dev.location}
                  </Box>

                  {dev.profilePicture && (
                    <CardMedia
                      component="img"
                      image={dev.profilePicture}
                      alt={dev.name}
                      sx={{
                        height: 200,
                        objectFit: "cover",
                        borderRadius: "10%",
                        margin: "0 auto",
                        marginBottom: "10px",
                      }}
                    />
                  )}

                  <CardContent sx={{ textAlign: "center", color: "#4d4d4d" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "'CustomFont', serif",
                        fontWeight: "bold",
                        color: "#4d4d4d",
                        marginBottom: "5px",
                      }}
                    >
                      {dev.name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontFamily: "'CustomFont', serif",
                        color: "#5b5b5b",
                        fontWeight: "500",
                      }}
                    >
                      {dev.profession}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        textAlign: "center",
                        color: "#b5651d",
                        mb: 3,
                      }}
                    >
                      {getExperienceLevel(dev)}
                      {dev.hasExperience && dev.experienceYears
                        ? ` | ${dev.experienceYears} years`
                        : ""}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        gap: 1,
                        marginTop: 2,
                      }}
                    >
                      {dev.skills.map((skill, index) => (
                        <Chip
                          key={index}
                          label={skill}
                          variant="outlined"
                          sx={{
                            borderColor: "#b5651d",
                            color: "#4d4d4d",
                            fontSize: "12px",
                          }}
                        />
                      ))}
                    </Box>
                  </CardContent>
                </Link>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default Saved;
