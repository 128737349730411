import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Avatar, Typography } from "@mui/material";
import { collection, query, where, getDocs } from "firebase/firestore"; // Firestore functions
import { db } from "../../firebaseConfig"; // Firebase configuration
import { addPost } from "./FeedService"; // Your function to add posts to Firestore

const ComposePost = ({ setPosts, user }) => {
  const [postContent, setPostContent] = useState("");
  const [profilePicture, setProfilePicture] = useState("/defaultAvatar.jpg"); // Default to a generic avatar initially

  useEffect(() => {
    // Only fetch if user exists and there's no photoURL available
    const fetchProfilePicture = async () => {
      if (user && !user.photoURL) {
        try {
          const developerQuery = query(
            collection(db, "developers"),
            where("userID", "==", user.uid) // Match the 'userID' field in the 'developers' collection with Firebase 'user.uid'
          );

          const developerSnapshot = await getDocs(developerQuery);

          if (!developerSnapshot.empty) {
            const developerData = developerSnapshot.docs[0].data();
            setProfilePicture(
              developerData.profilePicture || "/defaultAvatar.jpg"
            ); // Set the profile picture or fallback
          }
        } catch (error) {
          console.error("Error fetching profile picture:", error);
        }
      } else if (user?.photoURL) {
        // Use photoURL from the user object if available
        setProfilePicture(user.photoURL);
      }
    };

    fetchProfilePicture();
  }, [user]); // Only run the effect when `user` changes

  const handlePost = async () => {
    if (!user) {
      alert("You must be logged in to post.");
      return;
    }

    if (postContent.trim() === "") return;

    // Query the 'developers' collection where the userID field matches the Firebase user uid
    const developerQuery = query(
      collection(db, "developers"),
      where("userID", "==", user.uid) // Match the 'userID' field in the 'developers' collection with Firebase 'user.uid'
    );

    const developerSnapshot = await getDocs(developerQuery);

    if (developerSnapshot.empty) {
      console.error("No developer found with this userId");
      return;
    }

    const developerData = developerSnapshot.docs[0].data(); // Get the developer data

    // Create a new post with content and user-related fields
    const newPostData = {
      content: postContent,
      email: user?.email || "",
      userId: user?.uid || "",
      profilePicture: developerData.profilePicture || "/defaultAvatar.jpg", // Use profilePicture from the developer's data
      username: developerData.name || "User", // Use name from the developer's data
    };

    // Add the post to Firestore
    const newPost = await addPost(newPostData);

    // Attach developerInfo to the post to ensure it displays correctly
    const postWithDeveloperInfo = {
      ...newPost,
      developerInfo: {
        profilePicture: developerData.profilePicture || "/defaultAvatar.jpg",
        name: developerData.name || "User",
        github: developerData.github || "",
      },
    };

    // Update the state with the new post, including developer info
    setPosts((prev) => [postWithDeveloperInfo, ...prev]);
    setPostContent("");
  };

  if (!user) {
    return (
      <Typography
        variant="body1"
        sx={{ textAlign: "center", color: "var(--text-color-primary)" }}
      >
        התחבר על מנת לרשום.
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: "none",
        display: "flex",
        alignItems: "center",
        mb: 4,
      }}
    >
      <Avatar
        alt="User Avatar"
        src={profilePicture} // Display the profile picture
        sx={{ width: 36, height: 36 }}
      />
      <TextField
        fullWidth
        variant="outlined"
        value={postContent}
        onChange={(e) => setPostContent(e.target.value)}
        placeholder="על מה אתה חושב עכשיו?"
        sx={{
          ml: 2,
          backgroundColor: "var(--input-background)",
          borderColor: "var(--input-border-color)",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "var(--input-border-color)",
            },
            "&:hover fieldset": {
              borderColor: "var(--hover-color)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "var(--input-border-focus)",
            },
          },
        }}
      />
      <Button
        variant="contained"
        sx={{
          ml: 2,
          bgcolor: "var(--button-bg-primary)",
          color: "var(--button-text-color)",
          "&:hover": {
            bgcolor: "var(--button-bg-hover)",
          },
        }}
        onClick={handlePost}
      >
        פרסם
      </Button>
    </Box>
  );
};

export default ComposePost;
