// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ChatWindow.css */
.green-bubble {
  color: white !important;
  border-radius: 10px;
}

.left-bubble {
  background-color: #e0e0e0 !important; /* Gray color for received messages */
  color: #333 !important;
  border-radius: 10px;
}

.message-list .rce-mbox-right {
  text-align: right;
  background-color: #4caf50 !important; /* Green color for your messages */
  color: white !important;
  fill: #4caf50;
  border-radius: 10px;
}

.message-list .rce-mbox-left {
  text-align: left;
}
.rce-mbox-right-notch {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/chat/components/ChatWindow.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC,EAAE,qCAAqC;EAC3E,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,oCAAoC,EAAE,kCAAkC;EACxE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;AACf","sourcesContent":["/* ChatWindow.css */\n.green-bubble {\n  color: white !important;\n  border-radius: 10px;\n}\n\n.left-bubble {\n  background-color: #e0e0e0 !important; /* Gray color for received messages */\n  color: #333 !important;\n  border-radius: 10px;\n}\n\n.message-list .rce-mbox-right {\n  text-align: right;\n  background-color: #4caf50 !important; /* Green color for your messages */\n  color: white !important;\n  fill: #4caf50;\n  border-radius: 10px;\n}\n\n.message-list .rce-mbox-left {\n  text-align: left;\n}\n.rce-mbox-right-notch {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
