import React, { useState, useEffect } from "react";
import {
  TextField,
  IconButton,
  Button,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom"; // Import Link for routing
import DeleteIcon from "@mui/icons-material/Delete";

import {
  collection,
  addDoc,
  increment,
  getDocs,
  query,
  orderBy,
  where,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import ReactTimeAgo from "react-timeago"; // Import the package

const CommentSection = ({ postId, user, incrementCommentCount }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");

  const [userProfile, setUserProfile] = useState({
    profilePicture: "",
    name: "",
  });

  // Fetch the current user's profile data from 'developers'
  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user) {
        const userQuery = query(
          collection(db, "developers"),
          where("userID", "==", user.uid) // Query using the userID field
        );
        const userSnapshot = await getDocs(userQuery);
        if (!userSnapshot.empty) {
          const userData = userSnapshot.docs[0].data();
          setUserProfile({
            profilePicture: userData.profilePicture || "/defaultAvatar.jpg",
            name: userData.name || user.displayName || "User",
          });
        }
      }
    };

    fetchUserProfile();
  }, [user]);

  // Fetch comments
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const q = query(
          collection(db, `posts/${postId}/comments`),
          orderBy("timestamp", "desc")
        );
        const querySnapshot = await getDocs(q);
        const fetchedComments = await Promise.all(
          querySnapshot.docs.map(async (docSnapshot) => {
            const commentData = docSnapshot.data();

            // Fetch developer's data based on userID field in the comment
            const userQuery = query(
              collection(db, "developers"),
              where("userID", "==", commentData.userId) // Query using the userID field
            );
            const userSnapshot = await getDocs(userQuery);
            let userInfo = {};
            if (!userSnapshot.empty) {
              userInfo = userSnapshot.docs[0].data();
              userInfo.docId = userSnapshot.docs[0].id; // Include docId for linking
            }

            return {
              ...commentData,
              id: docSnapshot.id, // Store comment ID for deletion
              userInfo, // Attach the user's info (including name, profile picture, etc.)
            };
          })
        );
        setComments(fetchedComments);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, [postId]);
  // Handle comment submission
  const handleCommentSubmit = async () => {
    if (newComment.trim() === "") return;

    try {
      // Add comment to Firestore
      const commentRef = await addDoc(
        collection(db, `posts/${postId}/comments`),
        {
          content: newComment,
          timestamp: new Date(),
          userId: user.uid, // Store the user's Firebase Authentication ID
        }
      );

      // Update the post document to increment the comment count
      const postRef = doc(db, "posts", postId);
      await updateDoc(postRef, {
        commentsCount: increment(1), // Increment commentsCount by 1
      });
      // Call the function passed from the parent component to update the comment count
      incrementCommentCount();
      // Fetch the user's profile info (for name and profile picture)
      const userQuery = query(
        collection(db, "developers"),
        where("userID", "==", user.uid)
      );
      const userSnapshot = await getDocs(userQuery);
      let userInfo = {};
      if (!userSnapshot.empty) {
        userInfo = userSnapshot.docs[0].data();
        userInfo.docId = userSnapshot.docs[0].id; // Include docId for linking
      }

      // Create new comment object to add to local state
      const newCommentData = {
        content: newComment,
        timestamp: new Date(),
        userId: user.uid,
        userInfo: {
          profilePicture: userInfo.profilePicture || "/defaultAvatar.jpg",
          name: userInfo.name || "User",
          docId: userInfo.docId,
        },
      };

      // Update the comments state to include the new comment
      setComments((prevComments) => [newCommentData, ...prevComments]);

      // Clear the input after posting
      setNewComment("");
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  // Handle comment deletion
  const handleCommentDelete = async (commentId) => {
    try {
      await deleteDoc(doc(db, `posts/${postId}/comments`, commentId)); // Delete comment from Firestore
      setComments((prevComments) =>
        prevComments.filter((comment) => comment.id !== commentId)
      ); // Remove from state
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      {user ? (
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Box>
            <Avatar
              src={userProfile.profilePicture || "/defaultAvatar.jpg"} // Use profile picture from userProfile or default
              alt={userProfile.name || "User"}
              sx={{ width: 36, height: 36 }}
            />
          </Box>
          <TextField
            fullWidth
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="כתוב תגובה..."
            variant="outlined"
            multiline
            sx={{
              ml: 2,
              backgroundColor: "var(--input-background)",
              borderColor: "var(--input-border-color)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "var(--input-border-color)",
                },
                "&:hover fieldset": {
                  borderColor: "var(--hover-color)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "var(--input-border-focus)",
                },
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handleCommentSubmit}
            sx={{
              marginLeft: 2,
              bgcolor: "var(--button-bg-primary)",
              color: "var(--button-text-color)",
              "&:hover": {
                bgcolor: "var(--button-bg-hover)",
              },
            }}
          >
            שלח
          </Button>
        </Box>
      ) : (
        ""
      )}

      <Box
        sx={{
          maxHeight: "250px", // Limit the height to 250px
          overflowY: "scroll", // Enable vertical scrolling
          mt: 2,
        }}
      >
        {comments.map((comment, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              paddingY: 1,
              borderBottom:
                comments.length > 1 && index !== comments.length - 1
                  ? "1px solid var(--text-color-secondary)"
                  : "none", // Conditionally apply borderBottom
            }}
          >
            {/* Commenter's Avatar */}
            <Avatar
              src={comment.userInfo.profilePicture || "/defaultAvatar.jpg"} // Use profile picture or default
              alt={comment.userInfo.name || "User"}
              sx={{ width: 36, height: 36, marginRight: 2 }}
            />

            <Box sx={{ position: "relative", width: "100%" }}>
              <Link
                to={`/profile/${comment.userInfo.docId}`}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: "var(--text-color-primary)" }}
                >
                  <strong>{comment.userInfo.name || comment.userId}</strong>{" "}
                  {/* Display the user's name */}
                </Typography>
              </Link>

              <Typography
                variant="body1"
                sx={{ color: "var(--text-color-secondary)" }}
              >
                {comment.content}
              </Typography>
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    color: "var(--text-color-secondary)",
                  }}
                >
                  <Box sx={{ direction: "rtl", padding: 1 }}>
                    {comment.timestamp ? (
                      <ReactTimeAgo
                        date={
                          comment.timestamp instanceof Date
                            ? comment.timestamp // If it's already a Date object, use it directly
                            : new Date(comment.timestamp.seconds * 1000) // Convert Firestore Timestamp to JS Date
                        }
                      />
                    ) : (
                      "Unknown time"
                    )}
                  </Box>
                </Typography>
              </Box>

              {/* Delete button for the user's own comment, positioned at the top right */}
              {user && user.uid === comment.userId && (
                <IconButton
                  onClick={() => handleCommentDelete(comment.id)}
                  sx={{
                    color: "var(--text-color-secondary)",
                    position: "absolute", // Position absolutely within the comment box
                    top: 0, // Align to the top
                    right: 0, // Align to the right
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CommentSection;
