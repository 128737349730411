import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  Divider,
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import RssFeedIcon from "@mui/icons-material/RssFeed"; // Feed icon
import ChatIcon from "@mui/icons-material/Chat";

import StarIcon from "@mui/icons-material/Star";
import LinkIcon from "@mui/icons-material/Link";
import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MapIcon from "@mui/icons-material/Map";
import InfoIcon from "@mui/icons-material/Info";

import MenuIcon from "@mui/icons-material/Menu";
import WorkIcon from "@mui/icons-material/Work"; // Job board
import { useTheme } from "@mui/material/styles";
import { query, where, getDocs, collection } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../firebaseConfig";
import { onAuthStateChanged, signOut } from "firebase/auth";

const Header = ({ darkMode, setDarkMode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [user, setUser] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const q = query(
            collection(db, "developers"),
            where("email", "==", authUser.email)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userData = userDoc.data();

            setUser({
              uid: authUser.uid,
              email: authUser.email,
              displayName: authUser.displayName || authUser.email,
              profilePicture: userData.profilePicture || null,
            });
          } else {
            setUser(authUser);
          }
        } catch (error) {
          console.error("Error fetching Firestore document:", error);
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate("/login");
    });
  };

  const truncateEmail = (email) => {
    const [username, domain] = email.split("@");
    if (username.length <= 2) {
      return email;
    }
    return `${username[0]}****${username[username.length - 1]}@${domain}`;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const renderDrawer = () => (
    <Box
      sx={{ width: 300 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="בית" />
        </ListItem>
        <ListItem button component={Link} to="/map">
          <ListItemIcon>
            <MapIcon />
          </ListItemIcon>
          <ListItemText primary="מפה" />
        </ListItem>
        {user ? (
          <ListItem button component={Link} to="/chat">
            <ListItemIcon>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary="צ'אט" />
          </ListItem>
        ) : (
          ""
        )}

        <ListItem button component={Link} to="/feed">
          <ListItemIcon>
            <RssFeedIcon />
          </ListItemIcon>
          <ListItemText primary="פיד" />
        </ListItem>
        {!user ? (
          ""
        ) : (
          <ListItem button component={Link} to="/profile">
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="עריכת פרופיל" />
          </ListItem>
        )}
        <ListItem button component={Link} to="/about">
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="אודות" />
        </ListItem>
      </List>

      <Divider />

      {/* Coming Soon Items */}
      {user && (
        <List>
          <ListItem component={Link} to="/jobs">
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText primary="משרות" />
          </ListItem>

          {/* <ListItem>
          <ListItemIcon>
            <ArticleIcon />
          </ListItemIcon>
          <ListItemText primary="הבלוגריה (בקרוב)" />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary="המיטאפריה (בקרוב)" />
        </ListItem> */}
        </List>
      )}
      <Divider />

      {user ? (
        <>
          <ListItem
            sx={{
              display: "flex",
              padding: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
            button
            component={Link}
            to="/profile"
          >
            <ListItemIcon>
              <Avatar src={user.profilePicture || user.photoURL} />
            </ListItemIcon>
            <ListItemText
              sx={{ textAlign: "center" }}
              primary={truncateEmail(user.displayName || user.email)}
            />
          </ListItem>
          <ListItem
            sx={{
              textAlign: "center",
              display: "flex",
              padding: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
            button
            onClick={handleLogout}
          >
            <ListItemText primary="התנתק" />
          </ListItem>
        </>
      ) : (
        <Box>
          <ListItem button component={Link} to="/add">
            <ListItemText primary="הרשמה" />
          </ListItem>
          <ListItem button component={Link} to="/login">
            <ListItemText primary="התחברות" />
          </ListItem>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#b5651d",
          boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
          padding: isMobile ? "5px 0" : "10px 0",
          borderBottom: `5px solid ${darkMode ? "#D4AF37" : "#D4AF37"}`,
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component={Link}
              to="/"
              variant={isMobile ? "h6" : "h4"}
              sx={{
                display: "flex",
                alignItems: "center", // Ensure items are aligned properly
                fontFamily: "'CustomFont', serif",
                color: "#fff",
                fontSize: isMobile ? "1.5rem" : "1.75rem",
                textDecoration: "none", // No underline on link
                "&:hover": {
                  color: "#fff", // Keep color same on hover
                },
                "&:focus": {
                  color: "#fff", // Prevent color change on focus
                },
                "&:active": {
                  color: "#fff", // Prevent color change on click
                },
              }}
            >
              <Box
                id="header"
                sx={{
                  display: "flex",
                  alignItems: "center", // Ensure proper vertical alignment
                  gap: "2px", // Add a little space between the text and the icon
                }}
              >
                <span style={{ color: "#f5c518" }}>link</span>dev
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LinkIcon
                  sx={{
                    color: "#f5c518",
                    fontSize: isMobile ? "2.5rem" : "3rem",
                  }}
                />
              </Box>
            </Typography>
            <Typography sx={{ color: "white", paddingLeft: 1 }} variant="body1">
              רשת המפתחים של ישראל
            </Typography>
          </Box>

          {isMobile ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                color="inherit"
                component={Link}
                to="/saved"
                sx={{
                  color: "#FFD700",
                  "&:hover": {
                    color: "#FFD700", // Keep color same on hover
                  },
                  "&:focus": {
                    color: "#FFD700", // Prevent color change on focus
                  },
                  "&:active": {
                    color: "#FFD700", // Prevent color change on click
                  },
                }}
              >
                <StarIcon />
              </IconButton>

              <IconButton
                color="inherit"
                onClick={toggleDrawer(true)}
                sx={{ color: "#fff" }}
              >
                <MenuIcon />
              </IconButton>

              {/* Star Icon */}
            </Box>
          ) : (
            <Box sx={{ display: "flex", gap: 2 }}>
              {/* Coming Soon Items */}
              {/* <Button
                title="בלוגריה"
                sx={{
                  color: "#fff",
                  backgroundColor: "transparent",
                  borderRadius: "20px",
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: darkMode
                      ? "rgba(255,255,255,0.1)"
                      : "rgba(0,0,0,0.1)",
                  },
                }}
              >
                <ArticleIcon sx={{ fontSize: 30 }} />
              </Button> */}

              {user && (
                <Link
                  to="/jobs"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    display: "flex",
                  }}
                >
                  <Button
                    title="משרות"
                    sx={{
                      color: "#fff",
                      backgroundColor: "transparent",
                      borderRadius: "20px",
                      padding: 1,
                      "&:hover": {
                        backgroundColor: darkMode
                          ? "rgba(255,255,255,0.1)"
                          : "rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                    <WorkIcon sx={{ fontSize: 30 }} />
                  </Button>
                </Link>
              )}

              {/* 
              <Button
                title="מיטאפריה"
                sx={{
                  color: "#fff",
                  backgroundColor: "transparent",
                  borderRadius: "20px",
                  "&:hover": {
                    backgroundColor: darkMode
                      ? "rgba(255,255,255,0.1)"
                      : "rgba(0,0,0,0.1)",
                  },
                }}
              >
                <EventIcon sx={{ fontSize: 30 }} />
              </Button> */}
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: "rgba(255, 255, 255, 0.6)" }} // Customize the color and spacing
              />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  color="inherit"
                  component={Link}
                  to="/saved"
                  sx={{
                    color: "#FFD700",
                    "&:hover": {
                      color: "#FFD700", // Keep color same on hover
                    },
                    "&:focus": {
                      color: "#FFD700", // Prevent color change on focus
                    },
                    "&:active": {
                      color: "#FFD700", // Prevent color change on click
                    },
                  }}
                >
                  <StarIcon
                    sx={{
                      fontSize: 30,
                    }}
                  />
                </IconButton>
                {/* Star Icon */}
              </Box>

              <Link
                to="/about"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                }}
              >
                <Button
                  title="אודות"
                  color="inherit"
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "20px",
                    padding: 1,
                    transition: "0.3s",
                    "&:hover": {
                      backgroundColor: darkMode
                        ? "rgba(255,255,255,0.1)"
                        : "rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <InfoIcon sx={{ fontSize: 30 }} />
                </Button>
              </Link>

              <Link
                to="/map"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                }}
              >
                <Button
                  title="מפה"
                  color="inherit"
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "20px",
                    padding: 1,
                    transition: "0.3s",
                    "&:hover": {
                      backgroundColor: darkMode
                        ? "rgba(255,255,255,0.1)"
                        : "rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <MapIcon sx={{ fontSize: 30 }} />
                </Button>
              </Link>
              {/* Feed icon in mobile view */}
              <Link
                to="/feed"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                }}
              >
                <Button
                  color="inherit"
                  title="DevLink"
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "20px",
                    padding: 1,
                    transition: "0.3s",
                    "&:hover": {
                      backgroundColor: darkMode
                        ? "rgba(255,255,255,0.1)"
                        : "rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <RssFeedIcon sx={{ fontSize: 30 }} />
                </Button>
              </Link>
              <Link
                to="/chat"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                }}
              >
                <Button
                  color="inherit"
                  title="DevLink"
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "20px",
                    padding: 1,
                    transition: "0.3s",
                    "&:hover": {
                      backgroundColor: darkMode
                        ? "rgba(255,255,255,0.1)"
                        : "rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <ChatIcon sx={{ fontSize: 30 }} />
                </Button>
              </Link>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                }}
              >
                <Button
                  color="inherit"
                  title="DevLink"
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "20px",
                    padding: 1,
                    transition: "0.3s",
                    "&:hover": {
                      backgroundColor: darkMode
                        ? "rgba(255,255,255,0.1)"
                        : "rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <HomeIcon sx={{ fontSize: 30 }} />
                </Button>
              </Link>
              {user ? (
                <>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ borderColor: "rgba(255, 255, 255, 0.6)" }} // Customize the color and spacing
                  />

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Link
                      to="/profile"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "flex",
                      }}
                    >
                      <Button
                        color="inherit"
                        sx={{
                          backgroundColor: "transparent",
                          borderRadius: "20px",
                          padding: "10px",
                          transition: "0.3s",
                          "&:hover": {
                            backgroundColor: darkMode
                              ? "rgba(255,255,255,0.1)"
                              : "rgba(0,0,0,0.1)",
                          },
                        }}
                      >
                        <Avatar
                          alt={user.displayName || user.email}
                          src={user.profilePicture || user.photoURL}
                        />
                      </Button>
                    </Link>

                    <Box>
                      <Link
                        to="/profile"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          display: "flex",
                        }}
                      >
                        <Button
                          color="inherit"
                          sx={{
                            backgroundColor: "transparent",
                            borderRadius: "20px",
                            padding: "2px",
                            transition: "0.3s",
                            "&:hover": {
                              backgroundColor: darkMode
                                ? "rgba(255,255,255,0.1)"
                                : "rgba(0,0,0,0.1)",
                            },
                          }}
                        >
                          פרופיל
                        </Button>
                      </Link>
                      <Button
                        color="inherit"
                        onClick={handleLogout}
                        sx={{
                          backgroundColor: "transparent",
                          borderRadius: "20px",
                          padding: "2px",
                          transition: "0.3s",
                          "&:hover": {
                            backgroundColor: darkMode
                              ? "rgba(255,255,255,0.1)"
                              : "rgba(0,0,0,0.1)",
                          },
                        }}
                      >
                        התנתק
                      </Button>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ borderColor: "rgba(255, 255, 255, 0.6)" }} // Customize the color and spacing
                  />

                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ borderColor: "rgba(255, 255, 255, 0.6)" }} // Customize the color and spacing
                  />
                  <Link
                    to="/add"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                    }}
                  >
                    <Button
                      title="הרשמה"
                      color="inherit"
                      sx={{
                        padding: 1,
                        backgroundColor: "transparent",
                        borderRadius: "20px",
                        transition: "0.3s",
                        "&:hover": {
                          backgroundColor: darkMode
                            ? "rgba(255,255,255,0.1)"
                            : "rgba(0,0,0,0.1)",
                        },
                      }}
                    >
                      הרשמה
                    </Button>
                  </Link>
                  <Link
                    to="/login"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                    }}
                  >
                    <Button
                      title="התחברות"
                      color="inherit"
                      sx={{
                        padding: 1,
                        backgroundColor: "transparent",
                        borderRadius: "20px",
                        transition: "0.3s",
                        "&:hover": {
                          backgroundColor: darkMode
                            ? "rgba(255,255,255,0.1)"
                            : "rgba(0,0,0,0.1)",
                        },
                      }}
                    >
                      התחברות
                    </Button>
                  </Link>
                </Box>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Menu */}

      <Drawer
        sx={{ zIndex: "99999999999" }}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {renderDrawer()}
      </Drawer>
    </>
  );
};

export default Header;
