import React, { useState } from "react";
import {
  Button,
  Modal,
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  IconButton,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import { QRCodeSVG } from "qrcode.react";
import ReactCardFlip from "react-card-flip";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkIcon from "@mui/icons-material/Link";
import DownloadIcon from "@mui/icons-material/Download"; // Import the download icon

// Developer card (Front side)
import domtoimage from "dom-to-image";

const makeImage = () => {
  const card1 = document.querySelector(".react-card-front");

  if (!card1) {
    console.error("Unable to find the front card element.");
    return;
  }

  // Capture the front side (card1)
  domtoimage
    .toPng(card1)
    .then((dataUrl1) => {
      const link1 = document.createElement("a");
      link1.download = "card-front.png"; // Set the filename for the front card
      link1.href = dataUrl1;
      link1.click(); // Trigger download for the front card

      // Now download the back card from the provided URL
    })
    .catch((error) => {
      console.error("Error capturing the front card:", error);
    });
};

const DeveloperCard = ({
  name,
  position,
  avatarUrl,
  linkedin,
  github,
  whatsapp,
  email,
  website,
  profileUrl,
}) => {
  console.log(linkedin);

  // Function to extract LinkedIn username from URL
  const extractLinkedInUsername = (linkedinUrl) => {
    if (!linkedinUrl) return "";
    const urlParts = linkedinUrl.split("/");
    return urlParts[urlParts.length - 1] || urlParts[urlParts.length - 2]; // Get last part of the LinkedIn URL
  };

  return (
    <Card
      id="business-card-1"
      sx={{
        height: "100%",
        minWidth: 300,
        width: { xs: "100%", sm: "100%", md: 550 }, // Responsive width
        borderRadius: 4,
        backgroundColor: "#f5f5f5",
        margin: "0 auto",
        transition: "none", // Disable any transition
        "&:hover": {
          transform: "none", // Disable any hover transform
        },
      }}
    >
      <CardContent>
        <Grid container sx={{ padding: 0 }}>
          <Grid
            item
            xs={12}
            sm
            container
            direction="column"
            sx={{ padding: 0 }}
          >
            <Grid item sx={{ padding: 0 }}>
              <Avatar
                alt={name}
                src={avatarUrl}
                sx={{
                  width: 80,
                  height: 80,
                  margin: "auto",
                  border: "3px solid #b5651d",
                }}
              />
            </Grid>
            <Typography
              variant="h5"
              component="div"
              sx={{ color: "#b5651d", fontWeight: 700 }}
            >
              {name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {position}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      <CardContent>
        <Typography variant="h6" gutterBottom>
          דברו איתי
        </Typography>
        <Grid container sx={{}}>
          <Grid item xs={12} sm={12} md={4}>
            {linkedin && (
              <Box
                sx={{ flexDirection: "column" }}
                display="flex"
                alignItems="center"
              >
                <LinkedInIcon
                  sx={{ color: "#0072b1", fontSize: 22, marginRight: 1 }}
                />
                <Typography>{extractLinkedInUsername(linkedin)}</Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {whatsapp && (
              <Box
                sx={{ flexDirection: "column" }}
                display="flex"
                alignItems="center"
              >
                <WhatsAppIcon
                  sx={{ color: "#25D366", fontSize: 22, marginRight: 1 }}
                />
                <Typography>{whatsapp}</Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {email && (
              <Box
                sx={{ flexDirection: "column" }}
                display="flex"
                alignItems="center"
              >
                <EmailIcon
                  sx={{ color: "#0072b1", fontSize: 22, marginRight: 1 }}
                />
                <Typography>{email}</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>

      <CardContent sx={{ position: "absolute", top: 0, right: 0 }}>
        <Grid container justifyContent="center">
          <QRCodeSVG value={profileUrl} size={64} /> {/* Use QRCodeSVG */}
        </Grid>
      </CardContent>
    </Card>
  );
};

// Devlink card (Back side)
const DevlinkCard = () => {
  return (
    <Card
      id="business-card-2"
      sx={{
        minWidth: 300,
        width: { xs: "100%", sm: "100%", md: 550 }, // Responsive width
        height: "100%",
        borderRadius: 4,
        backgroundColor: "#b5651d",
        margin: "0 auto",
        transition: "none", // Disable any transition
        "&:hover": {
          transform: "none", // Disable any hover transform
        },
      }}
    >
      <CardContent sx={{ paddingBottom: 0, margin: 0, height: "100%" }}>
        <Box textAlign="center">
          <Box>
            <LinkIcon
              sx={{
                color: "#f5c518",
                fontSize: "8rem",
              }}
            />
          </Box>
          <Typography
            variant="h4"
            component="div"
            sx={{ color: "#fff", fontWeight: 700 }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center", // Ensure proper vertical alignment
                gap: "2px", // Add a little space between the text and the icon
              }}
            >
              <span style={{ color: "#f5c518" }}> link</span>dev
            </Box>
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#fff", fontWeight: 400, marginTop: 1 }}
          >
            רשת המפתחים של ישראל
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#f5f5f5", fontWeight: 300, marginTop: 1 }}
          >
            www.devlink.co.il
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

// Main Component with Flip and Modal
const BusinessCardModal = ({
  name,
  profession,
  profilePicture,
  linkedin,
  github,
  email,
  location,
  bio,
  skills,
  profileUrl, // CV or profile link
  whatsapp,
}) => {
  const [open, setOpen] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [showBackCard, setShowBackCard] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFlip = () => setIsFlipped(!isFlipped);
  // Function to extract LinkedIn username from URL
  const extractLinkedInUsername = (linkedinUrl) => {
    if (!linkedinUrl) return "";
    const urlParts = linkedinUrl.split("/");
    return urlParts[urlParts.length - 1] || urlParts[urlParts.length - 2]; // Gets last part or second last if trailing slash
  };

  return (
    <div>
      {/* Button to open modal */}
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{ backgroundColor: "#b5651d", color: "#fff" }}
      >
        צפה בכרטיס לקוח
      </Button>

      {/* Modal containing the flip card */}
      <Modal
        sx={{ padding: 0, margin: 0 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            margin: 0,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
          }}
        >
          <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            {/* Front side (Developer Card) */}
            <div className="react-card-front">
              <DeveloperCard
                name={name}
                position={profession}
                avatarUrl={profilePicture}
                linkedin={linkedin}
                github={github}
                email={email}
                location={location}
                bio={bio}
                skills={skills}
                profileUrl={profileUrl}
                whatsapp={whatsapp}
              />
            </div>

            <div className="react-card-back">
              <DevlinkCard />
            </div>
          </ReactCardFlip>
          <Button
            onClick={makeImage}
            sx={{
              position: "absolute",
              marginTop: 2,
              padding: 1,
              backgroundColor: "transparent",
              color: "#000",
              top: 0,
              left: 12,
            }}
          >
            <DownloadIcon sx={{ backgroundColor: "none" }} />{" "}
            {/* Use the download icon inside the button */}
          </Button>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleFlip}
              sx={{
                marginTop: 4,

                backgroundColor: "#b5651d",
                color: "#fff",
              }}
            >
              סובב
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default BusinessCardModal;
