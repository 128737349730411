import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth, db } from "../firebaseConfig"; // Make sure auth and db are exported from firebaseConfig
import { doc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { CircularProgress, Typography, Box } from "@mui/material";

const VerifyEmail = () => {
  const { userID } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const checkVerification = async () => {
      // Check if the user is logged in and their email is verified
      onAuthStateChanged(auth, async (user) => {
        if (user && user.uid === userID) {
          await user.reload(); // Refresh the user data to check email verification status
          if (user.emailVerified) {
            setIsVerified(true);
            // Update Firestore to mark the email as verified
            const developerRef = doc(db, "developers", userID);
            await updateDoc(developerRef, { emailVerified: true });
            // Update the emails collection
            const emailRef = doc(db, "emails", userID);
            await updateDoc(emailRef, { verified: true });
          }
        }
        setIsLoading(false);
      });
    };

    checkVerification();
  }, [userID]);

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", marginTop: 5 }}>
        <CircularProgress />
        <Typography variant="h6">Verifying email...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ textAlign: "center", marginTop: 5 }}>
      <Typography variant="h6">תודה על האישור.</Typography>
    </Box>

    // <Box sx={{ textAlign: "center", padding: 5 }}>
    //   {isVerified ? (
    //     <>
    //       <Typography variant="h4" gutterBottom>
    //         Email Verification Success!
    //       </Typography>
    //       <Typography variant="body1" sx={{ marginBottom: 3 }}>
    //         Your email has been successfully verified. You can now continue
    //         using the platform.
    //       </Typography>
    //       <Button variant="contained" onClick={handleContinue}>
    //         Continue to Homepage
    //       </Button>
    //     </>
    //   ) : (
    //     <Typography variant="h4" gutterBottom>
    //       Email not verified yet.
    //     </Typography>
    //   )}
    // </Box>
  );
};

export default VerifyEmail;
