import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  getDocs,
  orderBy,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { MessageList } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import { Box, Button, Avatar, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./ChatWindow.css";

const ChatWindow = ({ activeUser, currentUser, setActiveUser }) => {
  const [messages, setMessages] = useState([]);
  const [messageContent, setMessageContent] = useState("");
  const [chatId, setChatId] = useState(null);

  // Generate a unique chatId based on the user IDs
  const generateChatId = () => {
    return [currentUser.userID, activeUser.userID].sort().join("");
  };

  // Fetch or create chatId
  const fetchOrCreateChatId = async () => {
    const generatedChatId = generateChatId();

    const chatQuery = query(
      collection(db, "chats"),
      where("chatId", "==", generatedChatId)
    );

    const chatSnapshot = await getDocs(chatQuery);

    if (!chatSnapshot.empty) {
      setChatId(generatedChatId); // Use the existing chatId
    } else {
      // If no chat exists, create a new one
      await addDoc(collection(db, "chats"), {
        users: [currentUser.userID, activeUser.userID], // Store both user IDs in the chat
        createdAt: new Date(),
        chatId: generatedChatId,
      });
      setChatId(generatedChatId); // Set the newly created chatId
    }
  };

  // Fetch or create the chatId when the conversation starts
  useEffect(() => {
    if (activeUser && currentUser) {
      fetchOrCreateChatId();
    }
  }, [activeUser, currentUser]);

  // Mark all messages as read (only for the current user)
  const markMessagesAsRead = async () => {
    if (!chatId) return;

    const messagesQuery = query(
      collection(db, "messages"),
      where("chatId", "==", chatId),
      where("receiverId", "==", currentUser.userID), // Only mark messages intended for the current user
      where("read", "==", false) // Only get unread messages
    );

    const snapshot = await getDocs(messagesQuery);
    snapshot.forEach(async (messageDoc) => {
      const messageRef = doc(db, "messages", messageDoc.id);
      await updateDoc(messageRef, { read: true }); // Mark each message as read
    });
  };

  // Real-time listener for messages between the current user and the active user
  useEffect(() => {
    if (chatId) {
      const messagesQuery = query(
        collection(db, "messages"),
        where("chatId", "==", chatId),
        orderBy("timestamp", "asc")
      );

      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const chatMessages = snapshot.docs.map((doc) => doc.data());
        setMessages(chatMessages); // Automatically update messages in real-time

        markMessagesAsRead(); // Mark messages as read after they've been fetched
      });

      return () => unsubscribe(); // Cleanup the listener on unmount
    }
  }, [chatId]);

  // Handle sending a message
  const handleSendMessage = async () => {
    if (
      messageContent.trim() !== "" &&
      activeUser?.userID &&
      currentUser?.userID &&
      chatId
    ) {
      await addDoc(collection(db, "messages"), {
        text: messageContent,
        sender: currentUser.userID,
        receiverId: activeUser.userID, // Receiver ID is the active user
        chatId: chatId,
        timestamp: new Date(),
        read: false, // Mark as unread when sending
      });
      setMessageContent(""); // Clear input field
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f4f4f4",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      {/* Chat Header with Avatar and Name */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#ffffff",
          borderBottom: "1px solid #ddd",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Back button visible only on mobile */}
          <IconButton
            onClick={() => setActiveUser(null)}
            sx={{
              display: { xs: "flex", md: "none" },
              position: "absolute",
              right: 10,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Avatar
            alt={activeUser?.name}
            src={activeUser?.profilePicture || "/defaultAvatar.jpg"}
            sx={{ width: 40, height: 40, marginRight: 2 }}
          />
          <Typography variant="h6" sx={{ color: "#333" }}>
            {activeUser?.name}
          </Typography>
        </Box>
      </Box>

      {/* Chat Messages Section */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: "10px",
          backgroundColor: "#e0e0e0",
        }}
      >
        <MessageList
          className="message-list"
          lockable={true}
          toBottomHeight={"100%"}
          dataSource={messages.map((msg) => ({
            position: msg.sender === currentUser?.userID ? "right" : "left",
            type: "text",
            text: msg.text,
            date: new Date(msg.timestamp),
            className: msg.sender === currentUser?.userID ? "green-bubble" : "",
          }))}
        />
      </Box>

      {/* Message Input Section */}
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#ffffff",
          borderTop: "1px solid #ddd",
        }}
      >
        <input
          type="text"
          placeholder="רשום משהו..."
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleSendMessage();
          }}
          style={{
            flex: 1,
            border: "none",
            borderRadius: "20px",
            padding: "10px",
            marginRight: "10px",
            outline: "none",
          }}
        />
        <Button
          onClick={handleSendMessage}
          variant="contained"
          sx={{ bgcolor: "#4caf50", color: "#fff", borderRadius: "20px" }}
        >
          שלח
        </Button>
      </Box>
    </Box>
  );
};

export default ChatWindow;
