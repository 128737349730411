import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import Header from "./components/Header";
import Home from "./components/Home";
import AddMe from "./components/AddMe";
import Profile from "./components/Profile";
import Login from "./components/Login";
import ProfileView from "./components/ProfileView";
import MapView from "./components/MapView";
import EmailVerification from "./components/EmailVerification";
import VerifyEmail from "./components/VerifyEmail";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl"; // Create rtl cache
import About from "./components/About";
import Saved from "./components/Saved";
import Joberia from "./components/joberia/Joberia";
import Feed from "./components/Feed/Feed";
import "./styles/feedStyle.css";
import ChatApp from "./components/chat/ChatApp";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
// Create dynamic theme for dark and light modes
const createAppTheme = (darkMode) => {
  return createTheme({
    direction: "rtl", // Specify RTL
    palette: {
      mode: darkMode ? "dark" : "light", // Toggle between dark and light modes
      primary: {
        main: darkMode ? "#2A9D8F" : "#2A9D8F", // Primary color (Royal Green) stays the same for both modes
      },
      accent: {
        main: darkMode ? "#D4AF37" : "#D4AF37", // Accent color (Gold) stays the same for both modes
      },
      background: {
        default: darkMode ? "#1e1e1e" : "#F7F3E9", // Background for dark/light mode (Paper-like for light, Dark for dark mode)
        paper: darkMode ? "#121212" : "#FFFFFF", // Background for cards, paper elements
      },
      text: {
        primary: darkMode ? "#F7F3E9" : "#4A4A4A", // Text color (warm off-white in dark mode, charcoal in light mode)
        secondary: darkMode ? "#B1A7A6" : "#B1A7A6", // Secondary text color (warm grey for both modes)
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: darkMode ? "#2A9D8F" : "#2A9D8F", // Primary button color (Royal Green)
            borderRadius: "15px", // Rounded corners for buttons
            fontWeight: "bold",
            fontFamily: "'CustomFont', serif", // Serif font for a bookish style
            color: darkMode ? "#FFFFFF" : "#FFFFFF", // Button text color (always white)
            padding: "10px 20px",
            textTransform: "none", // Prevent text from being capitalized
            "&:hover": {
              backgroundColor: darkMode ? "#264653" : "#264653", // Darker hover color (Deep Forest Green)
              boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)", // Subtle shadow effect on hover
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: darkMode ? "#1e1e1e" : "#F7F3E9", // Input background color (Dark Grey or Off-White)
            borderRadius: "10px", // Rounded corners for inputs
            color: darkMode ? "#F7F3E9" : "#4A4A4A", // Input text color
          },
          notchedOutline: {
            borderColor: darkMode ? "#D4AF37" : "#4A4A4A", // Input border color (Gold for dark, Charcoal for light mode)
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: darkMode ? "#1e1e1e" : "#FFFFFF", // Card background color (Dark grey for dark mode, White for light)
            borderRadius: "20px", // Rounded corners for cards
            boxShadow: darkMode
              ? "0 10px 20px rgba(0, 0, 0, 0.5)"
              : "0 10px 20px rgba(0, 0, 0, 0.1)", // Subtle shadow effect for cards
            padding: "20px",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: darkMode ? "#D4AF37" : "#D4AF37", // Gold chips for tags/skills
            color: darkMode ? "#1e1e1e" : "#FFFFFF", // Dark chip text in light mode, white in dark mode
            fontSize: "12px", // Smaller font for chips to mimic the look of book tags
            borderRadius: "5px",
          },
        },
      },
    },
    typography: {
      fontFamily: "'CustomFont', serif", // Apply Lora for body text
      h1: {
        fontFamily: "'CustomFont', serif", // Use Playfair Display for headings for a classic, book-like look
        fontWeight: "bold",
        color: darkMode ? "#FFFFFF" : "#4A4A4A",
        fontSize: "48px",
      },
      h2: {
        fontFamily: "'CustomFont', serif",
        fontWeight: "bold",
        color: darkMode ? "#FFFFFF" : "#4A4A4A",
        fontSize: "36px",
      },
      body1: {
        fontFamily: "'CustomFont', serif",
        fontSize: "16px",
        color: darkMode ? "#F7F3E9" : "#4A4A4A",
        lineHeight: 1.7,
      },
      button: {
        fontFamily: "'CustomFont', serif",
        fontWeight: "bold",
      },
    },
  });
};

function App() {
  const [darkMode, setDarkMode] = useState(false);

  const theme = createAppTheme(darkMode);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        {" "}
        {/* Apply the dynamic theme */}
        <CssBaseline /> {/* Normalize CSS and apply the theme */}
        <Router>
          <Header darkMode={darkMode} setDarkMode={setDarkMode} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} /> {/* Login page route */}
            <Route path="/profile" element={<Profile />} />
            <Route path="/saved" element={<Saved />} />
            <Route path="/about" element={<About />} />
            <Route path="/jobs" element={<Joberia />} />
            <Route path="/feed" element={<Feed />} />
            <Route path="/chat" element={<ChatApp />} />
            {/* Profile page route */}
            <Route path="/add" element={<AddMe />} />
            <Route path="/map" element={<MapView />} />
            <Route path="/profile/:id" element={<ProfileView />} />
            <Route
              path="/email-verification/:userID"
              element={<EmailVerification />}
            />
            <Route path="/ver/:userID" element={<VerifyEmail />} />{" "}
            {/* New Route */}
          </Routes>
        </Router>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
