import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { auth, db } from "../../firebaseConfig";
import UserList from "./components/UserList";
import ChatWindow from "./components/ChatWindow";

const ChatApp = () => {
  const [currentUser, setCurrentUser] = useState(null); // Store current logged-in user
  const [activeUser, setActiveUser] = useState(null); // Store selected user for chat
  const [loading, setLoading] = useState(true);

  // Function to fetch user profile from Firestore based on email
  const fetchUserProfile = async (user) => {
    if (user) {
      try {
        const q = query(
          collection(db, "developers"),
          where("email", "==", user.email)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userProfile = querySnapshot.docs[0].data();
          setCurrentUser({ ...user, ...userProfile }); // Merge Firebase Auth user and Firestore data
        } else {
          console.log("No user profile found in Firestore.");
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    }
  };

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserProfile(user); // Fetch the user's profile from Firestore
      } else {
        setCurrentUser(null); // Handle case where no user is logged in
      }
      setLoading(false); // Stop loading once the auth state is determined
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <Box
      sx={{
        height: "calc(100vh - 100px)", // Take up the full viewport height
        width: "100vw", // Take up the full viewport width
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        sx={{
          height: { xs: "75vh", sm: "80vh", md: "75vh" }, // Adjust height based on screen size
          width: { xs: "95vw", md: "90vw" }, // Adjust width for smaller screens
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Optional: Add shadow for aesthetic
          backgroundColor: "white", // Ensure content has a white background
          borderRadius: "10px", // Optional: Add some border radius
          overflow: "hidden", // Ensure content doesn't overflow
        }}
      >
        {/* User list component */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            height: "100%",
            borderRight: { xs: "none", md: "1px solid #ddd" }, // Remove border on smaller screens
            borderBottom: { xs: "1px solid #ddd", md: "none" }, // Add border at the bottom on mobile
            display: { xs: activeUser ? "none" : "block", md: "block" }, // Hide user list on mobile when activeUser is selected
          }}
        >
          <UserList setActiveUser={setActiveUser} currentUser={currentUser} />
        </Grid>

        {/* Chat window component */}
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            height: "100%", // Ensure the chat window takes full height
          }}
        >
          {activeUser ? (
            <ChatWindow
              activeUser={activeUser}
              currentUser={currentUser}
              setActiveUser={setActiveUser} // Pass setActiveUser to allow back button
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: { xs: "1rem", md: "1.5rem" }, // Adjust text size for responsiveness
              }}
            >
              Select a user to start chatting
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatApp;
