import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getStorage } from "firebase/storage"; // Import Storage
import { getAuth } from "firebase/auth";
// Initialize Authentication
const firebaseConfig = {
  apiKey: "AIzaSyDoyLOiQTE5emDBAXKU6kbZrs_FNVwiYD4",
  authDomain: "web-dev-platform.firebaseapp.com",
  projectId: "web-dev-platform",
  storageBucket: "web-dev-platform.appspot.com",
  messagingSenderId: "586001572083",
  appId: "1:586001572083:web:6e25576ca06edb226e1786",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Initialize Firestore and Storage
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage, auth };
