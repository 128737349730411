import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import {
  Container,
  Typography,
  IconButton,
  Box,
  Chip,
  Avatar,
  Divider,
  CircularProgress,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import LanguageIcon from "@mui/icons-material/Language";
import DescriptionIcon from "@mui/icons-material/Description"; // CV Icon
import { useTheme, useMediaQuery } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShareIcon from "@mui/icons-material/Share";

const ProfileView = ({ darkMode }) => {
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [developer, setDeveloper] = useState(null);
  const getExperienceLevel = (developer) => {
    if (!developer.hasExperience) {
      return "Unexperienced";
    } else if (developer.experienceYears <= 5) {
      return "Junior";
    } else {
      return "Senior";
    }
  };
  const handleShare = () => {
    const profileUrl = window.location.href; // Get current URL
    navigator.clipboard.writeText(profileUrl).then(() => {
      alert("קישור לפרופיל הועתק בהצלחה");
    });
  };

  useEffect(() => {
    const fetchDeveloper = async () => {
      const docRef = doc(db, "developers", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setDeveloper(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchDeveloper();
  }, [id]);

  if (!developer) {
    return (
      <Container sx={{ textAlign: "center", marginTop: 5 }}>
        <CircularProgress sx={{ color: theme.palette.primary.main }} />
        <Typography variant="h6" sx={{ marginTop: 2, color: "text.primary" }}>
          טוען...
        </Typography>
      </Container>
    );
  }

  return (
    <React.Fragment>
      {/* Back Button */}
      <Container
        sx={{
          bgcolor: "background.default",
          padding: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Center vertically
          minHeight: "calc(100vh - 100px)", // Full viewport height
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {/* Open Book Style */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "stretch",
            width: "100%",
            maxWidth: isMobile ? "100%" : "1200px",
            minHeight: isMobile ? "auto" : "700px",
            boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fdf3e3",
            borderLeft: "22px solid #b5651d",
            borderRadius: "10px",
            borderRight: "22px solid #b5651d",
            overflow: "hidden",
            position: "relative",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          {/* Left Page */}
          {/* Left Page */}
          <Box
            sx={{
              zIndex: "9999",
              display: "flex",
              justifyContent: "center", // Center content horizontally
              alignItems: "center", // Center content vertically
              flexDirection: "column", // Stack content in a column
              flex: 1,
              padding: isMobile ? 2 : 4,
              minHeight: "100%", // Ensure full height for vertical centering
              boxShadow: isMobile
                ? "none"
                : "inset -5px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Avatar
              alt={developer.name}
              src={developer.profilePicture}
              sx={{
                width: isMobile ? 120 : 200,
                height: isMobile ? 120 : 200,
                margin: "0 auto",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                fontFamily: "CustomFontBold, serif",
                textAlign: "center",
                marginTop: 2,
                fontSize: isMobile ? "1.5rem" : "2rem",
                color: "#b5651d",
              }}
            >
              {developer.name}
            </Typography>

            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                fontSize: isMobile ? "1.1rem" : "1.25rem",
                color: "#b5651d",
                mb: 3,
              }}
            >
              {developer.profession}
            </Typography>
            {/* Experience Level */}
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                color: "#b5651d",
                mb: 3,
              }}
            >
              {getExperienceLevel(developer)}
              {developer.hasExperience && developer.experienceYears
                ? ` | ${developer.experienceYears} years`
                : ""}
            </Typography>

            {/* Skills */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: 1,
                marginTop: 2,
              }}
            >
              {developer.skills.map((skill, index) => (
                <Chip
                  key={index}
                  label={skill}
                  variant="outlined"
                  sx={{
                    borderColor: "#b5651d",
                    color: theme.palette.text.primary,
                    fontSize: "12px",
                  }}
                />
              ))}
            </Box>
          </Box>

          {/* Bookmark - Hide on Mobile */}
          {!isMobile && (
            <Box
              sx={{
                position: "absolute",
                top: "-20px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "40px",
                height: "80px",
                backgroundColor: "#b5651d",
                clipPath: "polygon(0 0, 100% 0, 100% 70%, 50% 100%, 0 70%)",
                zIndex: 10,
              }}
            />
          )}

          {/* Right Page */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 4,
              flexDirection: "column",
              flex: 1,
              zIndex: "9999",
              padding: isMobile ? 2 : 4,
            }}
          >
            {/* About Section */}
            <Typography
              variant="h5"
              sx={{
                fontFamily: "CustomFont, serif",
                marginBottom: 2,
                textAlign: "center",
                fontWeight: "bold",
                fontSize: isMobile ? "1.2rem" : "1.5rem",
                color: "#b5651d",
              }}
            >
              תקציר
            </Typography>

            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                marginBottom: 3,
                fontStyle: "italic",
                color: theme.palette.text.secondary,
              }}
            >
              {developer.bio && `${developer.bio}`}
            </Typography>

            <Box>
              {/* Location */}
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  marginBottom: 3,
                  color: theme.palette.text.primary,
                }}
              >
                מיקום: {developer.location}
              </Typography>

              <Divider sx={{ marginY: 3 }} />

              {/* External Links */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  zIndex: 9999,
                  marginBottom: 3,
                }}
              >
                {developer.website && (
                  <a
                    href={developer.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#b5651d" }}
                  >
                    <LanguageIcon sx={{ fontSize: 40, color: "#b5651d" }} />
                  </a>
                )}
                {developer.linkedin && (
                  <a
                    href={developer.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#b5651d" }}
                  >
                    <LinkedInIcon sx={{ fontSize: 40, color: "#b5651d" }} />
                  </a>
                )}
                {developer.github && (
                  <a
                    href={developer.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#b5651d" }}
                  >
                    <GitHubIcon sx={{ fontSize: 40, color: "#b5651d" }} />
                  </a>
                )}
                {developer.whatsapp && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://wa.me/${developer.whatsapp}?text=%D7%94%D7%99%D7%99%20-%20%D7%94%D7%92%D7%A2%D7%AA%D7%99%20%D7%93%D7%A8%D7%9A%20%D7%94%D7%93%D7%91%D7%9C%D7%95%D7%A4%D7%A8%D7%99%D7%94%20(:)`}
                    style={{ color: "#b5651d" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: 40, color: "#b5651d" }} />
                  </a>
                )}
                {developer.cvUrl && (
                  <a
                    href={developer.cvUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#b5651d" }}
                  >
                    <DescriptionIcon sx={{ fontSize: 40, color: "#b5651d" }} />
                  </a>
                )}
                {/* Share Icon */}
                <ShareIcon
                  onClick={handleShare}
                  sx={{ fontSize: 40, color: "#b5651d", cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default ProfileView;
