import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  CircularProgress,
  Modal,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { db, auth } from "../../firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";

import data from "../../data.json"; // Adjust the path based on your project structure

const { cities, professions } = data; // Destructure data
const Joberia = () => {
  const [userDocId, setUserDocId] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openJobModal, setOpenJobModal] = useState(false);
  const [openAppliedModal, setOpenAppliedModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  // eslint-disable-next-line
  const [user, setUser] = useState(null);
  const [sentCvJobs, setSentCvJobs] = useState([]);
  const [profession, setProfession] = useState("");
  const [location, setLocation] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [jobToApply, setJobToApply] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "jobs"));
        const jobList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setJobs(jobList);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
      setLoading(false);
    };

    fetchJobs();
  }, []);

  // Fetch the logged-in user and their sent CVs
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const q = query(
            collection(db, "developers"),
            where("email", "==", authUser.email)
          );
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userData = userDoc.data();
            setUser({
              uid: authUser.uid,
              email: authUser.email,
              displayName: authUser.displayName || authUser.email,
              profilePicture: userData.profilePicture || null,
            });
            setUserDocId(userDoc.id);
            setSentCvJobs(userData.sentCvJobs || []);
          }
        } catch (error) {
          console.error("Error fetching Firestore document:", error);
        }
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleOpenJobModal = (job) => {
    const alreadyApplied = sentCvJobs.includes(job.id);
    setSelectedJob({ ...job, alreadyApplied });
    setOpenJobModal(true);
  };
  const handleOpenConfirmModal = (jobId) => {
    setJobToApply(jobId);
    setOpenConfirmModal(true);
  };
  const handleConfirmSendCv = () => {
    handleSendCv(userDocId, jobToApply);
    setOpenConfirmModal(false); // Close the modal after confirming
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const handleCloseJobModal = () => {
    setOpenJobModal(false);
    setSelectedJob(null);
  };

  // Handle applied jobs modal
  const handleOpenAppliedModal = () => {
    setOpenAppliedModal(true);
  };

  const handleCloseAppliedModal = () => {
    setOpenAppliedModal(false);
  };

  const handleSendCv = async (userDocId, jobId) => {
    try {
      if (!userDocId) {
        console.error("User document ID not found");
        return;
      }

      // Add the job ID to the sentCvJobs array
      const updatedCvJobs = [...sentCvJobs, jobId];

      // Update the Firestore document using the document ID
      const userRef = doc(db, "developers", userDocId);
      await updateDoc(userRef, {
        sentCvJobs: updatedCvJobs, // Update the sentCvJobs field
      });

      // Update local state
      setSentCvJobs(updatedCvJobs);

      console.log("CV sent successfully to job ID:", jobId);

      // Close the job modal after sending CV
      handleCloseJobModal();
    } catch (error) {
      console.error("Error sending CV:", error);
    }
  };

  // Filter applied jobs
  const appliedJobs = jobs.filter((job) => sentCvJobs.includes(job.id));

  const filteredJobs = jobs.filter((job) => {
    const matchesProfession =
      profession === "" ||
      job.role.toLowerCase().includes(profession.toLowerCase());

    const matchesLocation =
      location === "" ||
      job.location.toLowerCase().includes(location.toLowerCase());
    const matchesSearch =
      searchTerm === "" ||
      job.role.toLowerCase().includes(searchTerm.toLowerCase());

    return (
      matchesProfession &&
      matchesLocation &&
      matchesSearch &&
      !sentCvJobs.includes(job.id) // Exclude already applied jobs
    );
  });

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", marginTop: 5 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container
      sx={{
        bgcolor: "background.default",
        minHeight: "100vh",
        padding: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          my: 3,
          fontFamily: "CustomFont, serif",
          textAlign: "center",
          color: "#b5651d",
        }}
      >
        לוח משרות
      </Typography>

      <Box sx={{ gap: "10px", display: "flex", justifyContent: "center" }}>
        {/* Button to View Applied Jobs */}
        <Button
          variant="contained"
          onClick={handleOpenAppliedModal}
          sx={{ marginBottom: 3, backgroundColor: "#b5651d" }}
        >
          משרות שהוגשו
        </Button>
        {/* Button to View Applied Jobs */}
        <Button
          variant="contained"
          sx={{ marginBottom: 3, backgroundColor: "#b5651d" }}
        >
          פרסמו אצלנו
        </Button>
      </Box>

      {/* Filters */}
      <Box sx={{ width: "100%", maxWidth: "900px", marginBottom: 3 }}>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={6} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>מקצוע</InputLabel>
              <Select
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
                label="מקצוע"
              >
                <MenuItem value="">All Professions</MenuItem>
                {professions.map((prof) => (
                  <MenuItem key={prof} value={prof}>
                    {prof}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>מיקום</InputLabel>
              <Select
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                label="מיקום"
              >
                <MenuItem value="">All Locations</MenuItem>
                {cities.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={8} sm={7}>
            <TextField
              label="חיפוש לפי שם"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Jobs Grid */}
      <Grid container spacing={3} sx={{ maxWidth: "1200px", padding: 2 }}>
        {filteredJobs.filter((job) => !sentCvJobs.includes(job.id)).length >
        0 ? (
          filteredJobs
            .filter((job) => !sentCvJobs.includes(job.id)) // Exclude already applied jobs
            .map((job) => (
              <Grid item xs={12} sm={6} md={4} key={job.id}>
                <Card
                  sx={{
                    transition: "0.3s",
                    "&:hover": {
                      transform: "translateY(-10px)",
                      boxShadow: "0 20px 40px rgba(0,0,0,0.2)",
                    },
                    borderRadius: "10px",
                    boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
                    overflow: "hidden",
                    bgcolor: "#fdf3e3",
                    position: "relative",
                    borderLeft: "22px solid #b5651d",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    height: "100%",
                    minHeight: "300px",
                    cursor: "pointer", // Make the entire card clickable
                  }}
                  onClick={() => handleOpenJobModal(job)}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0",
                      left: "-15px",
                      backgroundColor: "#b5651d",
                      color: "#fff",
                      padding: "5px 15px",
                      borderRadius: "0 0 10px 0",
                      fontSize: "12px",
                      fontFamily: "CustomFont",
                    }}
                  >
                    {job.location}
                  </Box>

                  <CardContent
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      textAlign: "center",
                      color: "#4d4d4d",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", marginBottom: "5px" }}
                    >
                      #{job.number}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", marginBottom: "5px" }}
                    >
                      {job.role}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "#8c8c8c", fontStyle: "italic" }}
                    >
                      {job.employmentType}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "#8c8c8c", fontStyle: "italic" }}
                    >
                      פורסם ב - {job.postedDate}
                    </Typography>
                    <Box>
                      <Box>שפות דרושות</Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          gap: 1,
                          marginTop: 2,
                        }}
                      >
                        {job.skills.map((skill, index) => (
                          <Chip
                            key={index}
                            label={skill}
                            variant="outlined"
                            sx={{
                              borderColor: "#b5651d",
                              color: "#4d4d4d",
                              fontSize: "12px",
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))
        ) : (
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              width: "100%",
              color: "#4d4d4d",
              marginTop: 5,
            }}
          >
            לא נמצאו משרות זמינות
          </Typography>
        )}
      </Grid>

      {/* Job Details Modal */}
      <Modal dir="rtl" open={openJobModal} onClose={handleCloseJobModal}>
        <Box
          sx={{
            cursor: "pointer",
            padding: { xs: 3, sm: 4 },
            bgcolor: "#fff",
            borderRadius: 3,
            maxWidth: { xs: "90%", sm: "600px" },
            margin: "auto",
            marginTop: { xs: "5vh", sm: "5vh" },
            maxHeight: "90vh", // Add this for scrollable content            maxHeight: "80vh", // Add this for scrollable content
            overflowY: "auto", // Allow scrolling when content overflows
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
            transition: "0.3s",
            textAlign: "center",
            direction: "ltr",
          }}
        >
          {selectedJob && (
            <>
              <Typography variant="h6" sx={{ marginBottom: 5 }}>
                {selectedJob.role}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 5 }}>
                <span style={{ fontWeight: "bold" }}>תיאור המשרה:</span>{" "}
                {selectedJob.description}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 5 }}>
                <span style={{ fontWeight: "bold" }}>דרישות התפקיד:</span>{" "}
                {selectedJob.requirements}
              </Typography>

              <Typography variant="body2" sx={{ marginBottom: 2 }}>
                מיקום: {selectedJob.location}
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: 2 }}>
                סוג העסקה: {selectedJob.employmentType}
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: 2 }}>
                תאריך פרסום: {selectedJob.postedDate}
              </Typography>

              {/* Show the button only if the user hasn't applied to the job */}
              {!selectedJob.alreadyApplied ? (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#b5651d",
                    borderRadius: "15px",
                    padding: "10px 20px",
                    "&:hover": {
                      backgroundColor: "#8b4513",
                      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                  onClick={() => handleOpenConfirmModal(selectedJob.id)} // Trigger confirmation modal
                >
                  שלח את הפרופיל שלי
                </Button>
              ) : (
                <Typography variant="body2" sx={{ color: "green" }}>
                  כבר שלחת קורות חיים למשרה זו. <br /> ניצור איתך קשר בהקדם
                  האפשרי.
                </Typography>
              )}
            </>
          )}
        </Box>
      </Modal>
      <Modal
        dir="rtl"
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
      >
        <Box
          sx={{
            cursor: "pointer",
            padding: { xs: 3, sm: 4 },
            bgcolor: "#fff",
            borderRadius: 3,
            maxWidth: { xs: "90%", sm: "600px" },
            margin: "auto",
            marginTop: { xs: "5vh", sm: "5vh" },
            textAlign: "center",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
            transition: "0.3s",
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 5 }}>
            האם אתה בטוח שברצונך לשלוח את קורות החיים למשרה זו?
          </Typography>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#b5651d", marginRight: 2 }}
            onClick={handleConfirmSendCv} // Confirm and proceed
          >
            כן
          </Button>
          <Button variant="outlined" onClick={handleCloseConfirmModal}>
            לא
          </Button>
        </Box>
      </Modal>

      {/* Applied Jobs Modal */}
      <Modal
        dir="rtl"
        open={openAppliedModal}
        onClose={handleCloseAppliedModal}
      >
        <Box
          sx={{
            cursor: "pointer",
            padding: { xs: 3, sm: 4 },
            bgcolor: "#fff",
            borderRadius: 3,
            maxWidth: { xs: "90%", sm: "600px" },
            marginTop: { xs: "5vh", sm: "5vh" },
            maxHeight: "90vh", // Add this for scrollable content            maxHeight: "80vh", // Add this for scrollable content
            margin: "auto",
            overflowY: "auto", // Allow scrolling when content overflows
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
            transition: "0.3s",
            textAlign: "center",
            direction: "ltr",
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 3 }}>
            משרות שהגשת להן קורות חיים
          </Typography>
          {appliedJobs.length > 0 ? (
            appliedJobs.map((job) => (
              <Box
                key={job.id}
                onClick={() => handleOpenJobModal(job)} // Open job modal on click
                sx={{
                  marginBottom: 2,
                  padding: 2,
                  borderRadius: 2,
                  backgroundColor: "#f7f7f7",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                    cursor: "pointer",
                  },
                }}
              >
                <Typography variant="body1">
                  #{job.number} - <strong>{job.role}</strong> - {job.location}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body1">
              עדיין לא הגשת קורות חיים למשרות.
            </Typography>
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default Joberia;
